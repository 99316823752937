<template>
  <div v-if="rendered">
    <VForm
      ref="form"
      @submit="onSubmit"
      :validation-schema="schema"
      :initial-values="formValues"
      v-slot="{ errors, values }"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="py-10 flex flex-col">
        <div class="bg-white container mx-auto w-11/12">
          <div class="rounded-lg shadow-md">
            <div
              class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 rounded-t-lg shadow-md"
            >
              <p class="p-2.5 text-white font-bold">Book Trade</p>
            </div>
            <div class="mx-3 mt-5 flex">
              <div class="w-6/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Trade Amount
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Your trade amount in Rand including the R500 SWIFT fee."
                    class="normal-case"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                  ></VuePopper>
                </label>
                <VField name="amount" v-slot="{ field }">
                  <input
                    v-number="amount_number"
                    v-bind="field"
                    class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                    :class="{ 'border-red-500': errors.amount }"
                /></VField>
                <div class="text-red-500 text-xs italic">
                  {{ errors.amount }}
                </div>
              </div>
              <div
                v-if="showAccountBalance"
                class="w-6/12 px-2 mb-2.5 text-left"
              >
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Account Balance
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="This is your current FX account balance."
                    class="normal-case"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                  ></VuePopper>
                </label>
                <div class="flex">
                  <span class="mt-2 ml-2">R</span>
                  <vue-number
                    v-model="account_balance"
                    v-number="amount_number"
                    class="w-full bg-white text-shiftlycharcoal border-none rounded py-2 px-1 mb-2 pointer-events-none"
                  ></vue-number>
                </div>
              </div>
            </div>
            <div class="mx-3 md:flex">
              <div class="md:w-12/12 px-2 mb-2.5 text-left">
                <p class="text-sm text-gray-600">
                  Amount that will be converted to foreign currency: R
                  <vue-number
                    v-model="final_amount"
                    v-number="balance"
                    class="border-none text-sm p-0 m-0 w-20 pointer-events-none"
                    >{{
                  }}</vue-number>
                  <br />
                  Amount that will be used to cover the SWIFT fee: R 500
                  <br />
                </p>
              </div>
            </div>
            <div class="mx-3 md:flex">
              <div class="md:w-6/12 px-2 mb-3.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Will this Trade fall under your SDA or FIA?</label
                >
                <div class="form-check">
                  <VField
                    name="allowance"
                    type="radio"
                    value="sda"
                    class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlycharcoal checked:bg-shiftlycharcoal checked:border-shiftlycharcoal focus:ring-1 focus:ring-shiftlycharcoal focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    :class="{ 'border-red-500': errors.allowance }"
                  />
                  <span class="tracking-wide text-shiftlyblue text-sm"
                    >SDA
                    <VuePopper
                      placement="top"
                      offsetDistance="4"
                      hover
                      content="Maximum R1m per calendar year."
                      class="normal-case text-xs font-bold"
                    >
                      <i
                        class="fa-solid fa-circle-info text-shiftlysunyellow"
                      ></i>
                    </VuePopper>
                  </span>
                </div>
                <VField
                  name="allowance"
                  type="radio"
                  value="fia"
                  class="rounded-full h-4 w-4 border border-gray-200 bg-white text-shiftlycharcoal checked:bg-shiftlycharcoal checked:border-shiftlycharcoal focus:ring-1 focus:ring-shiftlycharcoal focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  :class="{ 'border-red-500': errors.allowance }"
                />
                <span class="tracking-wide text-shiftlyblue text-sm"
                  >FIA
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Maximum R10m per calendar year. Each trade is limited to your FIA PIN amount."
                    class="normal-case text-xs font-bold"
                  >
                    <i
                      class="fa-solid fa-circle-info text-shiftlysunyellow"
                    ></i>
                  </VuePopper>
                </span>
                <div class="text-red-500 text-xs italic">
                  {{ errors.allowance }}
                </div>
              </div>
              <div
                v-if="values.allowance === 'sda'"
                class="w-6/12 px-2 mb-2.5 text-left"
              >
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >SDA Remaining
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="This is the remaining SDA you specified on Shiftly for the calendar year."
                    class="normal-case"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                  ></VuePopper>
                </label>
                <div class="flex">
                  <span class="mt-2 ml-2">R</span>
                  <vue-number
                    v-model="sda_remaining"
                    v-number="amount_number"
                    class="w-full bg-white text-shiftlycharcoal border-none rounded py-2 px-1 mb-2 pointer-events-none"
                  ></vue-number>
                </div>
              </div>
              <div
                v-else-if="values.allowance === 'fia'"
                class="w-6/12 px-2 mb-2.5 text-left"
              >
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >FIA Remaining
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="This is the remaining FIA you specified on Shiftly for the calendar year."
                    class="normal-case"
                  >
                    <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                  ></VuePopper>
                </label>
                <div class="flex">
                  <span class="mt-2 ml-2">R</span>
                  <vue-number
                    v-model="fia_remaining"
                    v-number="amount_number"
                    class="w-full bg-white text-shiftlycharcoal border-none rounded py-2 px-1 mb-2 pointer-events-none"
                  ></vue-number>
                </div>
              </div>
            </div>
            <div v-if="values.allowance === 'fia'" class="mx-3 md:flex">
              <div class="md:w-6/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Please provide us with your TCS Pin Letter</label
                >
                <VField
                  name="tcs_pin_letter"
                  v-slot="{ handleChange, handleBlur }"
                >
                  <input
                    id="tcs_pin_letter"
                    type="file"
                    accept=".pdf, application/pdf"
                    class="form-control block w-full px-3 py-1.5 mb-2 text-base font-normal text-shiftlycharcoal bg-white bg-clip-padding border border-solid border-gray-200 rounded transition ease-in-out hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                    :class="{ 'border-red-500': errors.tcs_pin_letter }"
                    @change="handleChange"
                    @blur="handleBlur"
                  />
                </VField>
                <div class="text-red-500 text-xs italic">
                  {{ errors.tcs_pin_letter }}
                </div>
                <p class="text-sm text-gray-600">
                  Please upload the original, unaltered TCS PIN letter in PDF
                  format as issued by SARS. We do not accept scanned documents
                  or PIN letters obtained by a 3rd party.
                  <br />
                </p>
              </div>
            </div>
            <div class="mx-3 md:flex">
              <div class="md:w-12/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Please Note:</label
                >
                <ul class="list-disc">
                  <li
                    class="tracking-wide text-shiftlycharcoal text-sm mt-1 ml-4"
                  >
                    Trades booked after
                    <span class="font-bold">08:30 am</span> will only be
                    processed the following business day.
                  </li>
                  <li
                    class="tracking-wide text-shiftlycharcoal text-sm mt-2 ml-4"
                  >
                    You are responsible for ensuring you do not exceed your
                    yearly SDA & FIA allowances.
                  </li>
                </ul>
              </div>
            </div>
            <div class="mx-3 mt-5 md:flex">
              <div class="md:w-12/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Disclosure Confirmation</label
                >
                <div class="flex">
                  <VField
                    name="terms"
                    class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                    type="checkbox"
                    :class="{ 'border-red-500': errors.terms }"
                    :value="true"
                    :unchecked-value="false"
                  />
                  <label
                    class="tracking-wide text-shiftlycharcoal text-sm mt-1"
                    for="terms"
                  >
                    By submitting this trade request I confirm that it is my
                    responsibility to ensure my funds are available to complete
                    this trade. I agree that should the funds be unavailable for
                    the trade to settle on the same day the exposure to forex
                    movement is at my own risk and the potential loss that might
                    result is for my own account.
                  </label>
                </div>
                <div class="text-red-500 text-xs italic">
                  {{ errors.terms }}
                </div>
              </div>
            </div>
            <div class="mx-3 flex mt-2">
              <div class="w-6/12">
                <router-link to="/home">
                  <button
                    type="button"
                    class="w-28 py-4 px-8 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  >
                    Cancel
                  </button>
                </router-link>
              </div>
              <div class="w-6/12">
                <button
                  :disabled="isLoading"
                  type="submit"
                  class="w-28 py-4 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  :class="[isLoading ? 'px-1' : 'px-8']"
                >
                  <span v-if="isLoading">
                    <i class="fa-solid fa-spinner animate-spin"></i>
                    Submitting
                  </span>
                  <span v-else>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
        >
          <div
            class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
          >
            <p class="p-2.5 text-white font-bold">
              30 Day Average Trading Returns
            </p>
          </div>
          <div class="m-5">
            <line-chart
              :data="averageData"
              suffix="%"
              :colors="['#62bcf6', '#ffbb10', '#0447e0']"
              :curve="false"
              :round="2"
              :zeros="true"
              :dataset="{ borderWidth: 2 }"
            ></line-chart>
          </div>
        </div>
      </div>
    </VForm>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapActions, mapState } from "pinia";
import { useApiStore } from "../stores/apiStore";
import {
  directive as VNumber,
  component as VueNumber,
} from "@coders-tm/vue-number-format";
import moment from "moment";

export default {
  name: "TradeForm",
  components: {
    VForm: Form,
    VField: Field,
    VueNumber,
  },
  data() {
    const schema = Yup.object().shape({
      amount: Yup.string().test("infoAmount", null, (amount) => {
        if (amount.length > 0) {
          this.final_amount = this.calculate(amount);
          if (this.checkTradeAmount(amount)) {
            return true;
          } else {
            return new Yup.ValidationError(
              "You are only authorised to trade up to R" +
                this.client.own_funds +
                " at a time, if you would like to increase this limit please contact support@shiftly.co.za",
              null,
              "amount"
            );
          }
        } else {
          return new Yup.ValidationError(
            "Trade Amount is required",
            null,
            "amount"
          );
        }
      }),
      allowance: Yup.string().test("infoAllowance", null, (allowance) => {
        if (allowance.length > 0) {
          if (this.checkRemainingAllowance(allowance)) {
            return true;
          } else {
            if (allowance === "sda") {
              return new Yup.ValidationError(
                "You will be exceeding your remaining SDA allowance with this trade of R" +
                  this.final_amount +
                  ", if you would like to increase this limit please contact support@shiftly.co.za",
                null,
                "allowance"
              );
            } else {
              return new Yup.ValidationError(
                "You will be exceeding your remaining FIA allowance with this trade of R" +
                  this.final_amount +
                  ", if you would like to increase this limit please contact support@shiftly.co.za",
                null,
                "allowance"
              );
            }
          }
        } else {
          return new Yup.ValidationError(
            "Please indicate SDA or FIA",
            null,
            "allowance"
          );
        }
      }),
      tcs_pin_letter: Yup.array().when("allowance", {
        is: (type) => type === "fia",
        then: Yup.array().test("uploadValid", null, (tcs_pin_letter) => {
          if (tcs_pin_letter.length > 0) {
            if (!this.checkFile(tcs_pin_letter[0])) {
              return new Yup.ValidationError(
                "Only the following format is accepted: .pdf",
                null,
                "tcs_pin_letter"
              );
            } else {
              return true;
            }
          } else {
            return new Yup.ValidationError(
              "TCS PIN Letter is Required",
              null,
              "tcs_pin_letter"
            );
          }
        }),
      }),
      terms: Yup.bool().oneOf(
        [true],
        "Please agree to the Disclosure Confirmation"
      ),
    });
    const formValues = {
      amount: 0,
      allowance: "",
      tcs_pin_letter: [],
      terms: false,
    };
    return {
      schema,
      formValues,
      isLoading: false,
      amount_number: {
        separator: ",",
        precision: 2,
        min: 0,
        max: 10000000,
        masked: false,
      },
      balance: {
        separator: ",",
        precision: 2,
        min: 0,
        masked: false,
      },
      rendered: false,
      account_balance: 0,
      showAccountBalance: false,
      final_amount: 0,
      currentDate: new Date(),
      targetYear: "",
      sda_remaining: 0,
      fia_remaining: 0,
      tradeInfo: {
        request_amount: 0,
        fx_transaction: {
          allowance: "",
          tcs_pin: null,
          tx_history: null,
        },
      },
      client: [],
      averageData: [],
    };
  },
  directives: {
    number: VNumber,
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client !== "") {
        //Check for latest deposit/withdraw activity
        this.getLatestActivity().then((result) => {
          if (result) {
            this.targetYear = this.currentDate.getFullYear();
            this.getAccountBalance(this.client.active_trading_account);
            this.checkHolidayorWeekend();
            ClientService.getAllowancesRemainingYear(this.targetYear).then(
              (remaining_allowances) => {
                if (remaining_allowances.data.length > 0) {
                  this.sda_remaining =
                    remaining_allowances.data[0]["sda_remaining"];
                  this.fia_remaining =
                    remaining_allowances.data[0]["fia_remaining"];
                }
              }
            );
            ClientService.getBatchProfits().then((profits) => {
              this.averageData = this.transformData(profits.data);
              this.rendered = true;
            });
          } else {
            this.$swal(
              "Deposit Required!",
              "Please create a deposit on the Deposits page. Your deposit must be verified by us before you can book a trade.",
              "info"
            );
            this.$router.push("/deposit");
          }
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateTradingStatus");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  created() {
    document.title = "Shiftly Client Portal - Trade";

    if (this.$cookies.isKey("amount")) {
      this.formValues.amount = this.$cookies.get("amount");
    }
  },
  computed: {
    ...mapState(useApiStore, {
      holidays: "holidays",
    }),
  },
  methods: {
    ...mapActions(useApiStore, ["postTCSPin", "postArbitrageTradeRequest"]),
    showError(errorTitle, errorText, errorFooter) {
      this.$swal({
        icon: "error",
        title: errorTitle,
        text: errorText,
        footer: errorFooter,
      });
    },
    showSuccess() {
      this.$swal(
        "Thank You!",
        "We have received your trade request",
        "success"
      );
    },
    checkFile(file) {
      const types = ["application/pdf"];
      if (types.includes(file.type)) {
        return true;
      } else {
        return false;
      }
    },
    checkTradeAmount(amount) {
      var tradeAmount = 0;
      if (!isNaN(Number(amount.replace(",", "")))) {
        tradeAmount = Number(amount.replace(",", ""));
      } else {
        var newTradeAmount = amount.replace(",", "");
        if (!isNaN(Number(newTradeAmount.replace(",", "")))) {
          tradeAmount = Number(newTradeAmount.replace(",", ""));
        }
      }
      var calculated_own_funds = Math.max(
        500 + Number(this.client.own_funds),
        0
      );
      if (tradeAmount <= calculated_own_funds) {
        return true;
      } else {
        return false;
      }
    },
    checkRemainingAllowance(allowance) {
      if (allowance === "sda") {
        if (this.final_amount <= this.sda_remaining) {
          return true;
        } else {
          return false;
        }
      } else if (allowance === "fia") {
        if (this.final_amount <= this.fia_remaining) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculate(amount) {
      var final = 0;
      if (!isNaN(Number(amount.replace(",", "")))) {
        final = Number(amount.replace(",", ""));
      } else {
        var newAmount = amount.replace(",", "");
        if (!isNaN(Number(newAmount.replace(",", "")))) {
          final = Number(newAmount.replace(",", ""));
        }
      }
      return Math.max(final - 500, 0);
    },
    getLatestActivity() {
      this.clientDeposit = [];
      this.clientWithdrawal = [];
      return new Promise((resolve) => {
        ClientService.getArbitrageDeposits().then((deposits) => {
          if (deposits.data.count > 0) {
            //Get latest completed deposit
            Object.entries(deposits.data.results).some(([key, value]) => {
              if (value.status == "verified") {
                this.clientDeposit = deposits.data.results[key];
                return true; // This breaks the loop
              }
            });

            if (this.clientDeposit == "") {
              // No verified deposit, redirect to deposit page
              resolve(false);
            } else {
              ClientService.getArbitrageWithdrawals().then((withdrawals) => {
                if (withdrawals.data.count > 0) {
                  //Get latest completed withdrawal
                  Object.entries(withdrawals.data.results).some(
                    ([key, value]) => {
                      if (value.completed) {
                        this.clientWithdrawal = withdrawals.data.results[key];
                        return true; // This breaks the loop
                      }
                    }
                  );

                  if (this.clientWithdrawal == "") {
                    // Has deposit but no withdrawal that is completed, continue on trade page
                    resolve(true);
                  } else {
                    if (
                      this.clientWithdrawal.created_at >=
                        this.clientDeposit.created_at &&
                      Number(this.clientWithdrawal.amount) >=
                        this.client.trade_size
                    ) {
                      //Latest action is withdrawal that is larger than trade size, redirect to deposit page
                      resolve(false);
                    } else {
                      //Latest action is deposit, continue on trade page
                      resolve(true);
                    }
                  }
                } else {
                  // Has deposit with no withdrawal, continue on trade page
                  resolve(true);
                }
              });
            }
          } else {
            // No deposit, redirect to deposit page
            resolve(false);
          }
        });
      });
    },
    getAccountBalance(url) {
      if (
        moment().format("HH:mm") >= "07:00" &&
        moment().format("HH:mm") <= "20:00"
      ) {
        ClientService.getAccountBalance(url)
          .then((balance) => {
            this.account_balance = balance.data.available_balance;
            this.showAccountBalance = true;
          })
          .catch((error) => {
            console.log(error);
            this.showAccountBalance = false;
          });
      }
    },
    checkHolidayorWeekend() {
      if (
        moment().format("dddd") == "Saturday" ||
        moment().format("dddd") == "Sunday"
      ) {
        this.$swal(
          "Please Note!",
          "Today is a weekend. If you book a trade today, it will only be processed on the next business day.",
          "info"
        );
      } else {
        Object.entries(this.holidays).forEach(([, value]) => {
          if (value.holiday_date == moment().format("YYYY-MM-DD")) {
            this.$swal(
              "Please Note!",
              "Today is a bank holiday in SA, the US, and/or the UK. Any trades booked today will be processed on the next business day.",
              "info"
            );
          }
        });
      }
    },
    onSubmit(values, actions) {
      this.isLoading = true;
      if (values.allowance === "fia") {
        this.onUploadFile(values.tcs_pin_letter)
          .then((response) => {
            this.tradeInfo.fx_transaction.tcs_pin = response.url;
            this.postToBackend(values);
          })
          .catch((error) => {
            if (error[0].includes("already exists")) {
              var pin = error[0].substring(8, 18);
              ClientService.getTCSPin(pin).then((response) => {
                this.tradeInfo.fx_transaction.tcs_pin = response.data.url;
                this.postToBackend(values);
                // }
              });
            } else {
              this.isLoading = false;
              console.log(error);
              actions.setFieldError(
                "tcs_pin_letter",
                "Error with upload, please try again."
              );
            }
          });
      } else {
        this.postToBackend(values);
      }
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    onUploadFile(files) {
      for (const i of Object.keys(files)) {
        //Build up FormData
        const formData = new FormData();
        formData.append("letter", files[i]);

        //sending payload to the backend
        return new Promise((resolve, reject) => {
          this.postTCSPin(formData)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              if (error.response && error.response.status == "400") {
                if (error.response.data["letter"] == "TCS PIN has expired.") {
                  this.isLoading = false;
                  this.showError(
                    "Invalid TCS pin letter!",
                    "Please upload a valid TCS pin letter that has not expired.",
                    ""
                  );
                } else if (
                  error.response.data["letter"] ==
                    "TCS PIN belongs to unknown client." ||
                  error.response.data["letter"] ==
                    "TCS PIN belongs to another client."
                ) {
                  this.isLoading = false;
                  this.showError(
                    "Invalid TCS pin letter!",
                    "Please upload a TCS pin letter that belongs to you.",
                    ""
                  );
                } else if (
                  error.response.data["letter"] ==
                    "TCS PIN letter is invalid." ||
                  error.response.data["letter"] ==
                    "TCS PIN letter cannot be read." ||
                  error.response.data["letter"] ==
                    "TCS PIN letter is not FIA/AIT."
                ) {
                  this.isLoading = false;
                  this.showError(
                    "Invalid TCS pin letter!",
                    "Please upload a valid TCS pin letter.",
                    ""
                  );
                } else {
                  reject(error.response.data["letter"]);
                }
              } else {
                this.isLoading = false;
                console.log(error);
                this.showError(
                  "Something went wrong!",
                  "Please contact Shiftly support for assistance",
                  '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>'
                );
              }
            });
        });
      }
    },
    //Api Submit
    postToBackend(values) {
      //adding trade amount to cookie
      this.$cookies.set("amount", values.amount);

      //parsing trade amount to number
      if (!isNaN(Number(values.amount.replace(",", "")))) {
        this.tradeInfo.request_amount = Number(values.amount.replace(",", ""));
      } else {
        var newAmount = values.amount.replace(",", "");
        if (!isNaN(Number(newAmount.replace(",", "")))) {
          this.tradeInfo.request_amount = Number(newAmount.replace(",", ""));
        }
      }

      //adding allowance to tradeInfo
      this.tradeInfo.fx_transaction.allowance = values.allowance;

      var arbitragePayload = { values: this.tradeInfo };

      // Sending payload to the backend
      this.postArbitrageTradeRequest(arbitragePayload).then(
        () => {
          this.isLoading = false;
          this.showSuccess();
          this.$router.push("/home");
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showError(
            "Something went wrong!",
            "Please contact Shiftly support for assistance",
            '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>'
          );
        }
      );
    },
    //Transform 30 Day Avg Data
    transformData(data) {
      const latestData = data.slice(0, 30);

      const minData = [];
      const avgData = [];
      const maxData = [];

      latestData.forEach((entry) => {
        minData.push([entry.created_at, entry.net_profit_min]);
        avgData.push([entry.created_at, entry.net_profit_avg]);
        maxData.push([entry.created_at, entry.net_profit_max]);
      });

      return [
        { name: "Minimum", data: minData },
        { name: "Average", data: avgData },
        { name: "Maximum", data: maxData },
      ];
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #ffbb10;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #ffbb10;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::file-selector-button {
  padding: 0.5rem 0.75rem;
}

.form-control::-webkit-file-upload-button {
  padding: 0.5rem 0.75rem;
}
</style>
