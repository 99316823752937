import api from "./api";
import TokenService from "./token.service";
class ClientService {
  getClientUrl() {
    return api.get("/auth/users/me/").then(
      (response) => {
        TokenService.setClientUrl(response.data);
        return Promise.resolve(response.data);
      },
      (error) => {
        console.log(error.response);
        return Promise.reject(error);
      }
    );
  }
  getClient(url) {
    return api.get(url);
  }
  getClientBankAccount(uuid) {
    return api.get("/v1/clients/bankaccounts/" + uuid + "/");
  }
  getClientBankAccounts() {
    return api.get("/v1/clients/bankaccounts/");
  }
  getClientBankAccountsOrdering(order) {
    return api.get("/v1/clients/bankaccounts/?ordering=" + order);
  }
  getDocuments() {
    return api.get("/v1/clients/documents/");
  }
  getDocumentsCategory(category) {
    return api.get("/v1/clients/documents/?category=" + category);
  }
  getDocumentsCategoryOrdering(category, order) {
    return api.get(
      "/v1/clients/documents/?category=" + category + "&ordering=" + order
    );
  }
  getAllowances() {
    return api.get("/v1/clients/foreigncapitalallowances/");
  }
  getAllowancesOrdering(order) {
    return api.get("/v1/clients/foreigncapitalallowances/?ordering=" + order);
  }
  getAllowancesYear(year) {
    return api.get("/v1/clients/foreigncapitalallowances/?year=" + year);
  }
  getAllowancesRemainingYear(year) {
    return api.get(
      "/v1/clients/foreigncapitalallowances/remaining/?year=" + year
    );
  }
  getAssetsandLiabilities(location) {
    return api.get(
      "/v1/clients/assetsliabilities/?location=" + location + "&ordering=-year"
    );
  }
  getIncomeandExpenses() {
    return api.get("/v1/clients/incomeexpenses/");
  }
  getTCSPin(pin) {
    return api.get("/v1/clients/tcspins/" + pin + "/");
  }
  getTCSPins() {
    return api.get("/v1/clients/tcspins/");
  }
  getClientReferrals() {
    return api.get("/v1/clients/clients/referrals/");
  }
  getAccountBalance(url) {
    return api.get(url + "balance/");
  }
  getAccountStatement(url, days) {
    return api.get(url + "statement/?days=" + days, { responseType: "blob" });
  }
  getTradeParameters() {
    return api.get("/v1/clients/tradeparameters/");
  }
  getTradeSummary(order) {
    return api.get(
      "/v1/arbitrage/trades/summary/?ordering=" + order + "&all=true"
    );
  }
  getTradeSummaryDownload(period, year) {
    return api.get(
      "/v1/arbitrage/trades/summary/download?period=" +
        period +
        "&year=" +
        year,
      { responseType: "blob" }
    );
  }
  getTradingStatement(url) {
    return api.get(url + "statement/download/", { responseType: "blob" });
  }
  getBatchProfits() {
    return api.get("/v1/arbitrage/batch/profits");
  }
  getArbitrageDeposits() {
    return api.get("/v1/arbitrage/deposits");
  }
  getArbitrageWithdrawals() {
    return api.get("/v1/arbitrage/withdrawals");
  }
  getAITApplications() {
    return api.get("/v1/premium/aitapplications");
  }
}
export default new ClientService();
