<template>
  <div v-if="currentRouteName === 'Withdraw' && rendered">
    <div class="container mx-auto w-11/12 mb-2 pt-10">
      <h2 class="text-lg text-left font-bold mb-2 text-shiftlycharcoal">
        Withdraw
      </h2>
      <h3 class="text-md text-left mb-2 text-shiftlycharcoal">
        Please choose one of the following options: <br />
        You can either add a new bank account or withdraw from an existing one
        that has already been added.
      </h3>
      <div class="text-right">
        <router-link to="/withdraw/bank-account">
          <button
            id="addButton"
            type="button"
            class="w-48 py-2 px-2 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
          >
            <i class="fa-solid fa-plus fa-fw"></i>
            Add bank account
          </button>
        </router-link>
      </div>
    </div>
    <div class="py-10 flex flex-col">
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div
          class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
        >
          <p class="p-2.5 text-white font-bold">Bank Accounts</p>
        </div>
        <div class="mx-3 mt-5 md:flex">
          <span class="px-2 mb-2.5 text-left text-shiftlycharcoal">
            Please see your bank accounts displayed below.
          </span>
        </div>
        <div class="w-11/12 mx-auto">
          <div id="card" class="py-2 inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="border-b uppercase text-shiftlyblue text-xs font-bold p-2 text-left"
                  >
                    Bank
                  </th>
                  <th
                    scope="col"
                    class="border-b uppercase text-shiftlyblue text-xs font-bold p-2 text-left"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="border-b uppercase text-shiftlyblue text-xs font-bold p-2 text-left"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in this.bankDetails"
                  v-bind:key="index"
                  class="rounded-lg xl:rounded-none shadow-md lg:shadow-none p-1"
                >
                  <td
                    data-label="Bank"
                    class="lg:border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                  >
                    <span
                      class="w-32 ss:w-36 xs:w-44 sm:w-60 xl:w-full mt-8 bg-white text-base text-shiftlycharcoal"
                      >{{ item.display_name }}</span
                    >
                  </td>
                  <td
                    data-label="Status"
                    class="lg:border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                  >
                    <span
                      class="w-32 ss:w-36 xs:w-44 sm:w-60 xl:w-full bg-white text-base text-shiftlycharcoal"
                      >{{ item.status }}</span
                    >
                  </td>
                  <td
                    data-label=""
                    class="lg:border-b p-2 whitespace-nowrap text-shiftlyblue text-left"
                  >
                    <button
                      id="deleteButton"
                      type="button"
                      class="text-base py-2 px-2 my-1.5 ml-2 lg:float-right bg-shiftlysunyellow hover:bg-[#e5a80f] text-white rounded-md"
                      :class="item.status == 'Verified' ? 'float-right' : ''"
                      @click.prevent="showConfirmDelete(item.url)"
                    >
                      Delete
                    </button>
                    <router-link
                      v-if="item.status == 'Verified'"
                      :to="{
                        path: '/withdraw/withdrawal',
                        query: { id: item.uuid },
                      }"
                    >
                      <button
                        id="withdrawButton"
                        type="button"
                        class="text-base py-2 px-2 my-1.5 lg:float-right bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                      >
                        Withdraw
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mx-3 my-5 md:flex">
          <p class="px-2 text-xs italic text-shiftlycharcoal">
            <span class="font-bold">Please note:</span> You can only withdraw to
            verified bank accounts.
          </p>
        </div>
      </div>
      <div
        class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
      >
        <div
          class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
        >
          <p class="p-2.5 text-white font-bold">Withdrawal History</p>
        </div>
        <div class="mx-3 mt-5 md:flex">
          <span class="px-2 mb-2.5 text-left text-shiftlycharcoal">
            Please see your withdrawal history displayed below.
          </span>
        </div>
        <div class="mx-auto w-11/12">
          <EasyDataTable
            :headers="headers"
            :items="clientWithdrawals"
            :rows-items="[25, 50, 100]"
            :rows-per-page="10"
            theme-color="#0447e0"
            table-class-name="customize-table"
            header-text-direction="left"
            body-text-direction="left"
          >
            <template #empty-message>
              <span class="text-shiftlycharcoal text-base"
                >No withdrawal data to display, please wait for first
                withdrawal.</span
              >
            </template>
            <template #item-amount="item">
              <span v-if="item.amount != null">R </span>
              <input
                v-model.lazy="item.amount"
                v-number="withdrawal_amount"
                class="border-none p-0 m-0 w-20 pointer-events-none bg-transparent"
              />
            </template>
            <template #item-completed="item">
              <span v-if="item.completed"
                ><i class="fa-solid fa-circle-check text-lg text-[#a5dc86]"></i
              ></span>
              <span v-else
                ><i class="fa-solid fa-circle-xmark text-lg text-[#f27474]"></i
              ></span>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapActions, mapState } from "pinia";
import { useApiStore } from "../stores/apiStore";
import { directive as VNumber } from "@coders-tm/vue-number-format";
import moment from "moment";

export default {
  name: "WithDraw",
  data() {
    return {
      rendered: false,
      withdrawal_amount: {
        separator: ",",
        precision: 2,
        min: 0,
        masked: false,
      },
      client: [],
      clientBanks: [],
      clientBankStatuses: [],
      bankAccountDetail: {
        status: "",
      },
      bankDetails: [],
      bankDetailsAll: [],
      clientWithdrawals: [],
      headers: [
        { text: "Date", value: "created_at", width: 110 },
        { text: "Bank Account", value: "bank_account", width: 350 },
        { text: "Amount", value: "amount", width: 140 },
        { text: "Submitted to Bank", value: "completed", width: 140 },
      ],
      fullUserName: localStorage.getItem("fullUserName"),
    };
  },
  directives: {
    number: VNumber,
  },
  //Used to get from path and update bank details list
  beforeRouteUpdate(to, from, next) {
    if (from.path === "/withdraw/bank-account") {
      this.rendered = false;
      this.getClientBankAccounts().then((result) => {
        if (result) {
          this.rendered = true;
        }
      });
    } else if (from.path === "/withdraw/withdrawal") {
      this.rendered = false;
      this.getClientWithdrawals().then((result) => {
        if (result) {
          this.rendered = true;
        }
      });
    }
    next();
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      this.clientBanks = this.banks.slice();
      this.clientBankStatuses = this.bankaccountstatuses.slice();
      if (this.client !== "") {
        if (this.client.status != "onboarded") {
          this.$router.push("/home");
        }
        this.getClientBankAccounts().then((result) => {
          if (result) {
            this.getClientWithdrawals().then((result) => {
              if (result) {
                this.rendered = true;
              }
            });
          } else {
            this.$router.push("/withdraw/bank-account");
          }
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateTradingStatus");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  updated() {
    document.title = "Shiftly Client Portal - Withdraw";
  },
  computed: {
    ...mapState(useApiStore, {
      banks: "banks",
      bankaccountstatuses: "bankaccountstatuses",
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useApiStore, ["patchBankDetails"]),
    getUUID(url) {
      return url.split("/").filter(Boolean).pop();
    },
    getClientBankAccounts() {
      this.bankDetails = [];
      this.bankDetailsAll = [];
      return new Promise((resolve) => {
        ClientService.getClientBankAccountsOrdering("created_at").then(
          (bankAccounts) => {
            if (bankAccounts.data.count > 0) {
              this.bankDetailsAll = bankAccounts.data.results;
              Object.entries(this.bankDetailsAll).forEach(([key, value]) => {
                for (key in this.clientBanks) {
                  if (this.clientBanks[key].url == value.bank) {
                    value.bankName = this.clientBanks[key].display_name;
                  }
                }
                for (key in this.clientBankStatuses) {
                  if (this.clientBankStatuses[key].value == value.status) {
                    value.status = this.clientBankStatuses[key].label;
                  }
                }
                value.uuid = this.getUUID(value.url);
                value.account_type =
                  value.account_type.charAt(0).toUpperCase() +
                  value.account_type.slice(1);
                value.display_name =
                  value.bankName +
                  " - " +
                  value.account_type +
                  " - " +
                  value.account_number;
              });
            }

            Object.entries(this.bankDetailsAll).forEach(([key, value]) => {
              if (value.intermediary == null && value.status != "Archived") {
                this.bankDetails.push(bankAccounts.data.results[key]);
              }
            });
            if (this.bankDetails.length > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      });
    },
    getClientWithdrawals() {
      this.clientWithdrawals = [];
      return new Promise((resolve) => {
        ClientService.getArbitrageWithdrawals().then((withdrawals) => {
          if (withdrawals.data.count > 0) {
            this.clientWithdrawals = this.transformData(
              withdrawals.data.results
            );
          }
        });
        resolve(true);
      });
    },
    showConfirmDelete(url) {
      this.$swal({
        title: "Are you sure?",
        text: "Once you press delete, this bank account will be removed. You'll need to add it again if you want to use it in the future.",
        icon: "warning",
        iconColor: "#ffbb10",
        showCancelButton: true,
        cancelButtonColor: "#0447e0",
        cancelButtonText: "Back",
        confirmButtonColor: "#dd3333",
        confirmButtonText: "Delete",
        reverseButtons: "true",
      }).then((result) => {
        if (result.isConfirmed) {
          this.patchToAPI(url);
        }
      });
    },
    patchToAPI(url) {
      //Sending payload to the backend
      this.bankAccountDetail.status = "archived";
      var payload = { url: url, values: this.bankAccountDetail };

      this.patchBankDetails(payload).then(
        () => {
          this.isLoading = false;
          this.$swal(
            "Success!",
            "Your bank account has been removed successfully.",
            "success"
          );
          this.getClientBankAccounts().then((result) => {
            if (result) {
              this.rendered = true;
            }
          });
        },
        (error) => {
          if (error.response.status == "400") {
            this.showError(error);
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.showError(error);
          }
        }
      );
    },
    transformData(data) {
      const withdrawals = [];

      Object.entries(data).forEach(([, value]) => {
        var withdrawal = {
          created_at: "",
          bank_account: "",
          withdraw_type: "",
          amount: "",
          completed: "",
        };

        withdrawal.created_at = moment(value.created_at).format("YYYY-MM-DD");
        withdrawal.amount = value.amount;
        withdrawal.completed = value.completed;

        Object.entries(this.bankDetailsAll).forEach(([, item]) => {
          if (item.url == value.bank_account) {
            withdrawal.bank_account = item.display_name;
          }
        });

        if (withdrawal.created_at >= "2024-03-03") {
          withdrawals.push(withdrawal);
        }
      });
      return withdrawals;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1023px) {
  #card table {
    border: 0;
  }

  #card table thead {
    display: none;
  }

  #card table tr {
    margin-bottom: 10px;
    display: block;
  }

  #card table td {
    display: block;
    text-align: right;
    font-size: 12px;
    margin-top: 10px;
  }

  #card table td:last-child {
    border-bottom: 0;
  }

  #card table td::before {
    content: attr(data-label);
    float: left;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.customize-table {
  --easy-table-border: none;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 42px;
  --easy-table-header-font-color: #0447e0;
  --easy-table-header-background-color: #ffffff;

  --easy-table-header-item-padding: 8px;

  --easy-table-body-even-row-font-color: #3c3c3d;
  --easy-table-body-even-row-background-color: #ffffff;

  --easy-table-body-row-font-color: #3c3c3d;
  --easy-table-body-row-background-color: #ffffff;
  --easy-table-body-row-height: 46px;
  --easy-table-body-row-font-size: 16px;

  --easy-table-body-row-hover-font-color: #3c3c3d;
  --easy-table-body-row-hover-background-color: transparent;

  --easy-table-body-item-padding: 8px;

  --easy-table-footer-background-color: #ffffff;
  --easy-table-footer-font-color: #3c3c3d;
  --easy-table-footer-font-size: 16px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;
}
</style>
