<template>
  <div v-if="rendered">
    <div class="container mx-auto w-11/12 mb-2 pt-10">
      <h2 class="text-lg text-left font-bold mb-2 text-shiftlycharcoal">
        AIT Applications
      </h2>
      <h3 class="text-md text-left mb-2 text-shiftlycharcoal">
        Below you will find a summary of all AIT applications that we have
        submitted on your behalf.
      </h3>
    </div>
    <div class="pt-9 pb-10 flex flex-col">
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div
          class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
        >
          <p class="p-2.5 text-white font-bold">AIT Application History</p>
        </div>
        <div class="mx-auto w-11/12 mt-3">
          <EasyDataTable
            :headers="headers"
            :items="clientAITApplications"
            :rows-items="[25, 50, 100]"
            :rows-per-page="10"
            theme-color="#0447e0"
            table-class-name="customize-table"
            header-text-direction="left"
            body-text-direction="left"
          >
            <template #empty-message>
              <span class="text-shiftlycharcoal text-base"
                >No ait application data to display, please wait for first ait
                application.</span
              >
            </template>
            <template #item-application_amount="item">
              <span v-if="item.application_amount != null">R </span>
              <input
                v-model.lazy="item.application_amount"
                v-number="application_amount"
                class="border-none p-0 m-0 w-20 pointer-events-none bg-transparent"
              />
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapState } from "pinia";
import { useApiStore } from "../stores/apiStore";
import { directive as VNumber } from "@coders-tm/vue-number-format";
import moment from "moment";

export default {
  name: "AITApplications",
  data() {
    return {
      rendered: false,
      application_amount: {
        separator: ",",
        precision: 2,
        min: 0,
        masked: false,
      },
      client: [],
      clientAITApplications: [],
      clientApplicationStatuses: [],
      clientTCSPins: [],
      headers: [
        {
          text: "Request Reference No",
          value: "request_reference",
          width: 250,
        },
        { text: "Application Date", value: "application_date", width: 130 },
        { text: "Amount", value: "application_amount", width: 140 },
        { text: "Application Status", value: "application_status", width: 160 },
        { text: "Days Elapsed", value: "days_elapsed", width: 110 },
        { text: "Tcs Pin", value: "tcs_pin", width: 110 },
        { text: "Completed Date", value: "completed_date", width: 130 },
      ],
      fullUserName: localStorage.getItem("fullUserName"),
    };
  },
  directives: {
    number: VNumber,
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      this.clientApplicationStatuses = this.applicationstatuses.slice();
      if (this.client !== "") {
        if (this.client.status != "onboarded") {
          this.$router.push("/home");
        }
        ClientService.getAITApplications().then((applications) => {
          if (applications.data.length > 0) {
            ClientService.getTCSPins().then((pins) => {
              if (pins.data.length > 0) {
                this.clientTCSPins = pins.data;
              }
              this.clientAITApplications = this.transformData(
                applications.data
              );
              this.rendered = true;
            });
          } else {
            this.rendered = true;
          }
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateTradingStatus");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  updated() {
    document.title = "Shiftly Client Portal - AIT Applications";
  },
  computed: {
    ...mapState(useApiStore, {
      applicationstatuses: "applicationstatuses",
    }),
  },
  methods: {
    transformData(data) {
      const applications = [];

      Object.entries(data).forEach(([key, value]) => {
        var application = {
          request_reference: "",
          application_date: "",
          application_amount: "",
          application_status: "",
          days_elapsed: "",
          tcs_pin: "",
          completed_date: "",
        };

        application.request_reference = value.request_reference;
        application.application_date = moment(value.application_date).format(
          "YYYY-MM-DD"
        );
        application.application_amount = value.application_amount;
        application.days_elapsed = value.days_elapsed;

        if (value.completed_date != null) {
          application.completed_date = moment(value.completed_date).format(
            "YYYY-MM-DD"
          );
        } else {
          application.completed_date = "";
        }

        const statusMap = {
          approved_pin_letter_unavailable: "approved",
          additional_docs_requested: "review_in_progress",
          docs_not_requested_yet: "review_in_progress",
          docs_prepared: "review_in_progress",
          status_not_available_yet: "review_in_progress",
        };

        if (statusMap[value.application_status]) {
          value.application_status = statusMap[value.application_status];
        }

        for (key in this.clientApplicationStatuses) {
          if (
            this.clientApplicationStatuses[key].value ==
            value.application_status
          ) {
            application.application_status =
              this.clientApplicationStatuses[key].label;
          }
        }

        Object.entries(this.clientTCSPins).forEach(([, item]) => {
          if (item.url == value.tcs_pin) {
            application.tcs_pin = item.pin;
          }
        });

        if (value.request_reference != null) {
          applications.push(application);
        }
      });

      return applications;
    },
  },
};
</script>

<style scoped>
.customize-table {
  --easy-table-border: none;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 42px;
  --easy-table-header-font-color: #0447e0;
  --easy-table-header-background-color: #ffffff;

  --easy-table-header-item-padding: 8px;

  --easy-table-body-even-row-font-color: #3c3c3d;
  --easy-table-body-even-row-background-color: #ffffff;

  --easy-table-body-row-font-color: #3c3c3d;
  --easy-table-body-row-background-color: #ffffff;
  --easy-table-body-row-height: 46px;
  --easy-table-body-row-font-size: 16px;

  --easy-table-body-row-hover-font-color: #3c3c3d;
  --easy-table-body-row-hover-background-color: transparent;

  --easy-table-body-item-padding: 8px;

  --easy-table-footer-background-color: #ffffff;
  --easy-table-footer-font-color: #3c3c3d;
  --easy-table-footer-font-size: 16px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;
}
</style>
