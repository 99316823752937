<template>
  <div>
    <!-- start:mobile menu -->
    <div
      v-if="showMobile"
      class="z-10 pt-16 absolute mobile-menu overflow-none w-full flex"
      :class="showMobile ? 'h-screen-plus' : 'h-screen'"
    >
      <div
        id="mobileMenu"
        class="w-64 text-shiftlycharcoal space-y-6 px-2 py-4 inset-y-0 left-0 bg-white"
      >
        <nav>
          <a
            @click="
              changeRoute('/home');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-home fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Dashboard</span
            >
          </a>
          <div
            v-if="this.serviceLevel !== ''"
            @click="showProfileNav = !showProfileNav"
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-id-badge fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Profile</span
            >
            <i v-if="!showProfileNav" class="fa-solid fa-angle-down fa-fw"></i>
            <i v-else class="fa-solid fa-angle-up fa-fw"></i>
          </div>
          <div
            v-show="showProfileNav && this.serviceLevel !== ''"
            class="pl-2.5"
          >
            <div v-for="item in profileStandardNavigation" :key="item.title">
              <div v-if="this.creditChoice === 'credit'">
                <a
                  v-if="item.title !== 'Source of Funds'"
                  :key="item.title"
                  @click="
                    changeRoute(item.route);
                    showMobile = !showMobile;
                  "
                  class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                >
                  <i :class="item.icon"></i>
                  <span
                    class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                    >{{ item.title }}</span
                  >
                </a>
              </div>
              <a
                v-else
                @click="
                  changeRoute(item.route);
                  showMobile = !showMobile;
                "
                class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
              >
                <i :class="item.icon"></i>
                <span
                  class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                  >{{ item.title }}</span
                >
              </a>
            </div>
            <div v-if="this.serviceLevel === 'premium'">
              <a
                v-for="item in profilePremiumNavigation"
                :key="item.title"
                @click="
                  changeRoute(item.route);
                  showMobile = !showMobile;
                "
                class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
              >
                <i :class="item.icon"></i>
                <span
                  class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                  >{{ item.title }}</span
                >
              </a>
            </div>
            <div>
              <div
                v-if="this.serviceLevel === 'premium'"
                @click="showAssetsLiabilitiesNav = !showAssetsLiabilitiesNav"
                class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
              >
                <i class="fa-solid fa-scale-balanced fa-fw"></i>
                <span
                  class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                  >Assets and Liabilities</span
                >
                <i
                  v-if="!showAssetsLiabilitiesNav"
                  class="fa-solid fa-angle-down fa-fw"
                ></i>
                <i v-else class="fa-solid fa-angle-up fa-fw"></i>
              </div>
            </div>
            <div
              v-show="showAssetsLiabilitiesNav && this.serviceLevel !== ''"
              class="pl-2.5"
            >
              <div v-if="this.serviceLevel === 'premium'">
                <a
                  v-for="item in profilePremiumAssetsLiabilitiesNavigation"
                  :key="item.title"
                  @click="
                    changeRoute(item.route);
                    showMobile = !showMobile;
                  "
                  class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                >
                  <i :class="item.icon"></i>
                  <span
                    class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                    >{{ item.title }}</span
                  >
                </a>
              </div>
            </div>
            <div
              v-if="
                this.creditChoice === 'top_up' || this.creditChoice === 'credit'
              "
            >
              <a
                v-for="item in profilePremiumCreditNavigation"
                :key="item.title"
                @click="
                  changeRoute(item.route);
                  showMobile = !showMobile;
                "
                class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
              >
                <i :class="item.icon"></i>
                <span
                  class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                  >{{ item.title }}</span
                >
              </a>
            </div>
          </div>
          <a
            v-if="this.clientStatus === 'onboarded'"
            @click="
              changeRoute('/deposit');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-circle-dollar-to-slot fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Deposit</span
            >
          </a>
          <a
            v-if="
              this.clientStatus === 'onboarded' &&
              this.serviceLevel === 'standard' &&
              this.tradingStatus === 'active_not_submitting_aits'
            "
            @click="
              changeRoute('/trade');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-money-bill-transfer fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Trade</span
            >
          </a>
          <a
            v-if="this.clientStatus === 'onboarded'"
            @click="
              changeRoute('/withdraw');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-hand-holding-dollar fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Withdraw</span
            >
          </a>
          <a
            v-if="this.clientStatus === 'onboarded'"
            @click="
              changeRoute('/transactions');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-chart-line fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Transactions</span
            >
          </a>
          <div
            v-if="this.clientStatus === 'onboarded'"
            @click="showCapitecNav = !showCapitecNav"
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-landmark fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Capitec</span
            >
            <i v-if="!showCapitecNav" class="fa-solid fa-angle-down fa-fw"></i>
            <i v-else class="fa-solid fa-angle-up fa-fw"></i>
          </div>
          <div
            v-show="showCapitecNav && this.clientStatus === 'onboarded'"
            class="pl-2.5"
          >
            <a
              v-for="item in capitecNavigation"
              :key="item.title"
              @click="
                changeRoute(item.route);
                showMobile = !showMobile;
              "
              class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i :class="item.icon"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                >{{ item.title }}</span
              >
            </a>
          </div>
          <a
            v-if="
              this.clientStatus === 'onboarded' &&
              this.serviceLevel === 'premium'
            "
            @click="
              changeRoute('/ait-applications');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-file-contract fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >AIT Applications</span
            >
          </a>
          <a
            v-if="
              this.clientStatus === 'onboarded' &&
              this.referralActive === 'true'
            "
            @click="
              changeRoute('/referrals');
              showMobile = !showMobile;
            "
            class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
          >
            <i class="fa-solid fa-user-plus fa-fw"></i>
            <span class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
              >Referrals</span
            >
          </a>
        </nav>
      </div>
      <div
        @click="hideMobileMenu()"
        class="flex-1 bg-opacity-50 bg-black"
      ></div>
    </div>
    <!-- end:mobile menu -->

    <div
      class="flex flex-col"
      :class="showMobile ? 'h-screen-plus' : 'h-screen'"
    >
      <!--  start::navbar   -->
      <nav
        class="w-full"
        :class="
          !$route.meta.hideNavbar
            ? 'drop-shadow-lg sticky-top bg-white'
            : 'bg-shiftlybackground1'
        "
      >
        <div class="mx-auto px-2 md:px-6">
          <div class="relative flex items-center justify-between h-16">
            <div
              v-if="!$route.meta.hideNavbar"
              class="absolute inset-y-0 left-0 flex items-center md:hidden"
            >
              <button
                @click="showMobile = !showMobile"
                class="inline-flex items-center justify-center p-2 rounded-md hover:text-shiftlycharcoal"
              >
                <span class="sr-only">Open main menu</span>
                <i
                  class="fa-solid fa-bars fa-fw text-shiftlycharcoal text-xl"
                ></i>
              </button>
            </div>
            <div
              v-if="!$route.meta.hideNavbar"
              class="flex-1 flex items-center justify-center md:items-stretch md:justify-start"
            >
              <div class="flex-shrink-0 flex items-center">
                <div id="HeaderLogo" class="relative w-32">
                  <a href="https://www.shiftly.co.za">
                    <img
                      v-if="!showDark"
                      alt="Shiftly logo"
                      src="./assets/Shiftly-logo-blue.png"
                    />
                    <img
                      v-else
                      alt="Shiftly logo"
                      src="./assets/Shiftly-logo-white.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              v-if="!$route.meta.hideNavbar"
              class="absolute inset-y-0 right-0 items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0 flex"
            >
              <div class="flex justify-center">
                <div class="dropdown relative">
                  <button
                    class="dropdown-toggle px-1 py-2.5 text-shiftlycharcoal transition duration-200 ease-in-out flex items-center whitespace-nowrap focus:outline-none focus:ring-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="hidden md:block">{{ this.fullUserName }}</span>
                    <span class="block md:hidden">{{
                      this.shortUserName
                    }}</span>
                    <i class="fa-solid fa-angle-down fa-fw ml-1"></i>
                  </button>
                  <ul
                    class="dropdown-menu bg-gradient-to-b from-shiftlybackground1 to-shiftlybackground2 py-2 text-left rounded-lg drop-shadow-md hidden"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item py-2 px-4 block w-full whitespace-nowrap cursor-pointer text-shiftlycharcoal hover:bg-shiftlysunyellow hover:text-shiftlycharcoal"
                        @click="
                          changeRoute('/profile/change-password');
                          this.hideMobileMenu();
                        "
                        ><i class="fa-solid fa-key fa-fw pr-2"></i>Change
                        Password</a
                      >
                    </li>
                    <hr
                      class="h-0 my-2 border border-solid border-t-0 border-shiftlycharcoal opacity-50"
                    />
                    <li>
                      <a
                        class="dropdown-item py-2 px-4 block w-full whitespace-nowrap cursor-pointer text-shiftlycharcoal hover:bg-shiftlysunyellow hover:text-shiftlycharcoal"
                        @click.prevent="logOut"
                        ><i
                          class="fa-solid fa-arrow-right-from-bracket fa-fw pr-2"
                        >
                        </i
                        >Sign Out</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!--  end::navbar   -->
      <div
        class="flex flex-1"
        :class="
          !$route.meta.hideNavbar
            ? 'overflow-hidden bg-white'
            : 'bg-gradient-to-b from-shiftlybackground1 to-shiftlybackground2'
        "
      >
        <!--   start::Sidebar    -->
        <aside
          v-if="!$route.meta.hideNavbar"
          class="hidden md:block w-64 overflow-y-auto text-shiftlycharcoal space-y-6 px-2 mt-16 md:mt-0 py-4 inset-y-0 left-0"
        >
          <nav>
            <a
              @click="changeRoute('/home')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-home fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Dashboard</span
              >
            </a>
            <div
              v-if="this.serviceLevel !== ''"
              @click="showProfileNav = !showProfileNav"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-id-badge fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Profile</span
              >
              <i
                v-if="!showProfileNav"
                class="fa-solid fa-angle-down fa-fw"
              ></i>
              <i v-else class="fa-solid fa-angle-up fa-fw"></i>
            </div>
            <div
              v-show="showProfileNav && this.serviceLevel !== ''"
              class="pl-2.5"
            >
              <div v-for="item in profileStandardNavigation" :key="item.title">
                <div v-if="this.creditChoice === 'credit'">
                  <a
                    v-if="item.title !== 'Source of Funds'"
                    :key="item.title"
                    @click="changeRoute(item.route)"
                    class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                  >
                    <i :class="item.icon"></i>
                    <span
                      class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                      >{{ item.title }}</span
                    >
                  </a>
                </div>
                <a
                  v-else
                  @click="changeRoute(item.route)"
                  class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                >
                  <i :class="item.icon"></i>
                  <span
                    class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                    >{{ item.title }}</span
                  >
                </a>
              </div>
              <div v-if="this.serviceLevel === 'premium'">
                <a
                  v-for="item in profilePremiumNavigation"
                  :key="item.title"
                  @click="changeRoute(item.route)"
                  class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                >
                  <i :class="item.icon"></i>
                  <span
                    class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                    >{{ item.title }}</span
                  >
                </a>
              </div>
              <div>
                <div
                  v-if="this.serviceLevel === 'premium'"
                  @click="showAssetsLiabilitiesNav = !showAssetsLiabilitiesNav"
                  class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                >
                  <i class="fa-solid fa-scale-balanced fa-fw"></i>
                  <span
                    class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                    >Assets and Liabilities</span
                  >
                  <i
                    v-if="!showAssetsLiabilitiesNav"
                    class="fa-solid fa-angle-down fa-fw"
                  ></i>
                  <i v-else class="fa-solid fa-angle-up fa-fw"></i>
                </div>
              </div>
              <div
                v-show="showAssetsLiabilitiesNav && this.serviceLevel !== ''"
                class="pl-2.5"
              >
                <div v-if="this.serviceLevel === 'premium'">
                  <a
                    v-for="item in profilePremiumAssetsLiabilitiesNavigation"
                    :key="item.title"
                    @click="changeRoute(item.route)"
                    class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                  >
                    <i :class="item.icon"></i>
                    <span
                      class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                      >{{ item.title }}</span
                    >
                  </a>
                </div>
              </div>
              <div
                v-if="
                  this.creditChoice === 'top_up' ||
                  this.creditChoice === 'credit'
                "
              >
                <a
                  v-for="item in profilePremiumCreditNavigation"
                  :key="item.title"
                  @click="changeRoute(item.route)"
                  class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
                >
                  <i :class="item.icon"></i>
                  <span
                    class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                    >{{ item.title }}</span
                  >
                </a>
              </div>
            </div>
            <a
              v-if="this.clientStatus === 'onboarded'"
              @click="changeRoute('/deposit')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-circle-dollar-to-slot fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Deposit</span
              >
            </a>
            <a
              v-if="
                this.clientStatus === 'onboarded' &&
                this.serviceLevel === 'standard' &&
                this.tradingStatus === 'active_not_submitting_aits'
              "
              @click="changeRoute('/trade')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-money-bill-transfer fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Trade</span
              >
            </a>
            <a
              v-if="this.clientStatus === 'onboarded'"
              @click="changeRoute('/withdraw')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-hand-holding-dollar fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Withdraw</span
              >
            </a>
            <a
              v-if="this.clientStatus === 'onboarded'"
              @click="changeRoute('/transactions')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-chart-line fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Transactions</span
              >
            </a>
            <div
              v-if="this.clientStatus === 'onboarded'"
              @click="showCapitecNav = !showCapitecNav"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-landmark fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Capitec</span
              >
              <i
                v-if="!showCapitecNav"
                class="fa-solid fa-angle-down fa-fw"
              ></i>
              <i v-else class="fa-solid fa-angle-up fa-fw"></i>
            </div>
            <div
              v-show="showCapitecNav && this.clientStatus === 'onboarded'"
              class="pl-2.5"
            >
              <a
                v-for="item in capitecNavigation"
                :key="item.title"
                @click="changeRoute(item.route)"
                class="group flex items-center space-x-1.5 py-3 px-3 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
              >
                <i :class="item.icon"></i>
                <span
                  class="text-shiftlycharcoal group-hover:text-shiftlycharcoal text-sm"
                  >{{ item.title }}</span
                >
              </a>
            </div>
            <a
              v-if="
                this.clientStatus === 'onboarded' &&
                this.serviceLevel === 'premium'
              "
              @click="changeRoute('/ait-applications')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-file-contract fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >AIT Applications</span
              >
            </a>
            <a
              v-if="
                this.clientStatus === 'onboarded' &&
                this.referralActive === 'true'
              "
              @click="changeRoute('/referrals')"
              class="group flex items-center space-x-1.5 py-3 px-4 cursor-pointer hover:bg-shiftlysunyellow rounded hover:text-shiftlycharcoal transition duration-200"
            >
              <i class="fa-solid fa-user-plus fa-fw"></i>
              <span
                class="text-shiftlycharcoal group-hover:text-shiftlycharcoal"
                >Referrals</span
              >
            </a>
          </nav>
        </aside>
        <!--   end::Sidebar    -->
        <!--   start::Main Content     -->
        <main
          class="flex-1 overflow-y-auto paragraph"
          :class="
            !$route.meta.hideNavbar
              ? 'bg-gradient-to-b from-shiftlybackground1 to-shiftlybackground2'
              : ''
          "
        >
          <router-view />
        </main>
        <!--   end::Main Content     -->
      </div>
      <footer
        class="text-sm sticky-bottom bg-shiftlybackground2"
        :class="!$route.meta.hideNavbar && !this.moveFooter ? 'ml-64' : ''"
      >
        <div class="px-4 py-3 text-shiftlycharcoal hidden lg:block">
          © 2019-{{ this.footerYear }} Shiftly FX (Pty) Ltd. All rights
          reserved. Authorised Financial Services Provider (FSP 51806)
        </div>
        <div class="px-4 py-3 text-shiftlycharcoal ss:block lg:hidden">
          © 2019-{{ this.footerYear }} Shiftly FX (Pty) Ltd. All rights
          reserved. <br />
          Authorised Financial Services Provider (FSP 51806)
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import EventBus from "./common/EventBus";
import { mapActions } from "pinia";
import { useApiStore } from "./stores/apiStore";

export default {
  name: "App",
  data() {
    return {
      showMobile: false,
      showProfileNav: false,
      showAssetsLiabilitiesNav: false,
      showCapitecNav: false,
      moveFooter: false,
      showDark: false,
      fullUserName: "",
      shortUserName: "",
      clientStatus: "",
      serviceLevel: "",
      tradingStatus: "",
      creditChoice: "",
      referralActive: "",

      footerYear: new Date().getFullYear(),
      profileStandardNavigation: [
        {
          route: "/profile/personal-information",
          title: "Personal Information",
          icon: "fa-solid fa-user fa-fw",
        },
        {
          route: "/profile/contact-details",
          title: "Contact Details",
          icon: "fa-solid fa-address-card fa-fw",
        },
        {
          route: "/profile/fica-documents",
          title: "FICA Documents",
          icon: "fa-solid fa-file fa-fw",
        },
        {
          route: "/profile/employment-information",
          title: "Employment Information",
          icon: "fa-solid fa-briefcase fa-fw",
        },
        {
          route: "/profile/source-of-funds",
          title: "Source of Funds",
          icon: "fa-solid fa-wallet fa-fw",
        },
        {
          route: "/profile/allowance-information",
          title: "Allowance Information",
          icon: "fa-solid fa-file-invoice-dollar fa-fw",
        },
      ],
      profilePremiumNavigation: [
        {
          route: "/profile/efiling-information",
          title: "eFiling Information",
          icon: "fa-solid fa-money-bill-wave fa-fw",
        },
      ],
      profilePremiumAssetsLiabilitiesNavigation: [
        {
          route: "/profile/local-assets-and-liabilities",
          title: "Local",
          icon: "fa-solid fa-plane-arrival fa-fw",
        },
        {
          route: "/profile/foreign-assets-and-liabilities",
          title: "Foreign",
          icon: "fa-solid fa-plane-departure fa-fw",
        },
      ],
      profilePremiumCreditNavigation: [
        {
          route: "/profile/income-and-expenses",
          title: "Income & Expenses",
          icon: "fa-solid fa-file-invoice fa-fw",
        },
      ],
      capitecNavigation: [
        {
          route: "/capitec/transaction-history",
          title: "Transaction History",
          icon: "fa-solid fa-clock-rotate-left fa-fw",
        },
      ],
    };
  },
  mounted() {
    this.onResize(), window.addEventListener("resize", this.onResize);

    EventBus.on("logout", () => {
      this.logOut();
    });

    EventBus.on("updateUserName", () => {
      this.getFullUserName();
      this.getShortUserName();
    });

    EventBus.on("updateClientStatus", () => {
      this.getClientStatus();
    });

    EventBus.on("updateServiceLevel", () => {
      this.getServiceLevel();
    });

    EventBus.on("updateTradingStatus", () => {
      this.getTradingStatus();
    });

    EventBus.on("updateCreditChoice", () => {
      this.getCreditChoice();
    });

    EventBus.on("updateReferralActive", () => {
      this.getReferralActive();
    });

    EventBus.on("reload", () => {
      this.$router.go(0);
    });

    //data call to get and set countries
    this.setCountries();
    //data call to get and set nationalities
    this.setNationalities();
    //data call to get and set banks
    this.setBanks();
    //data call to get and set holidays
    this.setHolidays();
    //data call to get and set titles
    this.setTitles();
    //data call to get and set marital statuses
    this.setMaritalStatuses();
    //data call to get and set net worths
    this.setNetWorths();
    //data call to get and set provinces
    this.setProvinces();
    //data call to get and set poatypes
    this.setProofOfAddressTypes();
    //data call to get and set employmentStatuses
    this.setEmploymentStatuses();
    //data call to get and set softypes
    this.setSourceOfFundsTypes();
    //data call to get and set bankaccount statuses
    this.setBankAccountStatuses();
    //data call to get and set application statuses
    this.setApplicationStatuses();
  },
  beforeUnmount() {
    EventBus.remove("logout");
  },
  methods: {
    ...mapActions(useApiStore, [
      "logout",
      "setCountries",
      "setNationalities",
      "setBanks",
      "setHolidays",
      "setTitles",
      "setProvinces",
      "setProofOfAddressTypes",
      "setEmploymentStatuses",
      "setSourceOfFundsTypes",
      "setMaritalStatuses",
      "setNetWorths",
      "setBankAccountStatuses",
      "setApplicationStatuses",
    ]),
    onResize() {
      if (window.innerWidth >= 767) {
        this.showMobile = false;
        this.moveFooter = false;
      } else {
        this.moveFooter = true;
      }
    },
    hideMobileMenu() {
      this.showMobile = false;
    },
    logOut() {
      this.hideMobileMenu();
      this.logout();

      this.$router.push("/sign-in");
    },
    getFullUserName() {
      this.fullUserName = localStorage.getItem("fullUserName");
    },
    getShortUserName() {
      this.shortUserName = localStorage.getItem("shortUserName");
    },
    getClientStatus() {
      this.clientStatus = localStorage.getItem("clientStatus");
    },
    getServiceLevel() {
      this.serviceLevel = localStorage.getItem("serviceLevel");
    },
    getTradingStatus() {
      this.tradingStatus = localStorage.getItem("tradingStatus");
    },
    getCreditChoice() {
      this.creditChoice = localStorage.getItem("creditChoice");
    },
    getReferralActive() {
      this.referralActive = localStorage.getItem("referralActive");
    },
    changeRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.h-screen-plus {
  height: 120vh !important;
}
</style>
