<template>
  <router-view></router-view>
</template>

<script>
import EventBus from "../common/EventBus";

export default {
  name: "CapitecBusiness",
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateTradingStatus");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
};
</script>

<style scoped></style>
