<template>
  <div v-if="rendered">
    <VForm
      ref="form"
      @submit="onSubmit"
      :validation-schema="schema"
      :initial-values="formValues"
      v-slot="{ errors, values }"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="py-10 flex flex-col">
        <div class="bg-white container mx-auto w-11/12">
          <div class="rounded-lg shadow-md">
            <div
              class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 rounded-t-lg shadow-md"
            >
              <p class="p-2.5 text-white font-bold">Request Trade Summary</p>
            </div>
            <div class="mx-3 mt-5 md:flex">
              <div class="md:w-6/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Period
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Please select the time period for your trade summary."
                    class="normal-case"
                  >
                    <i
                      class="fa-solid fa-circle-info text-shiftlysunyellow"
                    ></i></VuePopper
                ></label>
                <VField
                  name="period"
                  as="select"
                  class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                  :class="{
                    'border-red-500': errors.period,
                  }"
                >
                  <option value=""></option>
                  <option value="all_time">All Time</option>
                  <option value="calendar_year">Calendar Year</option>
                  <option value="tax_year">Tax Year</option>
                </VField>
                <div class="text-red-500 text-xs italic">
                  {{ errors.period }}
                </div>
              </div>
            </div>
            <div
              v-if="
                values.period === 'tax_year' ||
                values.period === 'calendar_year'
              "
              class="mx-3 md:flex"
            >
              <div class="md:w-6/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Year
                  <VuePopper
                    placement="top"
                    offsetDistance="4"
                    hover
                    content="Please select the year for your selected time period."
                    class="normal-case"
                  >
                    <i
                      class="fa-solid fa-circle-info text-shiftlysunyellow"
                    ></i></VuePopper
                ></label>
                <VField
                  name="year"
                  as="select"
                  class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                  :class="{
                    'border-red-500': errors.year,
                  }"
                >
                  <option
                    v-for="year in years"
                    :value="year.value"
                    :text="year.type"
                    :key="year.value"
                  />
                </VField>
                <div class="text-red-500 text-xs italic">
                  {{ errors.year }}
                </div>
              </div>
            </div>
            <div class="mx-3 md:flex">
              <div class="md:w-12/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Please Note:</label
                >
                <ul class="list-disc">
                  <li
                    class="tracking-wide text-shiftlycharcoal text-sm mt-1 ml-4"
                  >
                    You can download a trade summary for all time, calendar
                    year, or a tax year by selecting an option from the dropdown
                    menus.
                  </li>
                  <li
                    class="tracking-wide text-shiftlycharcoal text-sm mt-1 ml-4"
                  >
                    The information in these reports is provided to help you
                    prepare your tax return. Shiftly accepts no responsibility
                    or liability for any tax matters. Although we have taken
                    care to ensure the accuracy of the information, we recommend
                    verifying it against your own records.
                  </li>
                </ul>
              </div>
            </div>
            <div class="mx-3 mt-5 md:flex">
              <div class="md:w-12/12 px-2 mb-2.5 text-left">
                <label
                  class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                  >Disclosure Confirmation</label
                >
                <div class="flex">
                  <VField
                    name="terms"
                    class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                    type="checkbox"
                    :class="{ 'border-red-500': errors.terms }"
                    :value="true"
                    :unchecked-value="false"
                  />
                  <label
                    class="tracking-wide text-shiftlycharcoal text-sm mt-1"
                    for="terms"
                  >
                    By submitting this request I acknowledge and understand the
                    above.
                  </label>
                </div>
                <div class="text-red-500 text-xs italic">
                  {{ errors.terms }}
                </div>
              </div>
            </div>
            <div class="mx-3 flex mt-2">
              <div class="w-6/12">
                <router-link to="/home">
                  <button
                    type="button"
                    class="w-28 py-4 px-8 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  >
                    Cancel
                  </button>
                </router-link>
              </div>
              <div class="w-6/12">
                <button
                  :disabled="isLoading"
                  type="submit"
                  class="w-28 py-4 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                  :class="[isLoading ? 'px-1' : 'px-8']"
                >
                  <span v-if="isLoading">
                    <i class="fa-solid fa-spinner animate-spin"></i>
                    Submitting
                  </span>
                  <span v-else>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
        >
          <div
            class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
          >
            <p class="p-2.5 text-white font-bold">Trade Summary</p>
          </div>
          <div class="mx-3 mt-5 md:flex">
            <span class="px-2 mb-2.5 text-left text-shiftlycharcoal">
              Please see your trade summary displayed below.
            </span>
          </div>
          <div class="mx-auto w-11/12">
            <EasyDataTable
              :headers="headers"
              :items="clientTrades"
              :rows-items="[25, 50, 100]"
              :rows-per-page="10"
              theme-color="#0447e0"
              table-class-name="customize-table"
              header-text-direction="left"
              body-text-direction="left"
            >
              <template #empty-message>
                <span class="text-shiftlycharcoal text-base"
                  >No trade data to display, please wait for first trade.</span
                >
              </template>
              <template #item-base_cost="item">
                R
                <input
                  v-model.lazy="item.base_cost"
                  v-number="amount"
                  class="border-none p-0 m-0 w-20 pointer-events-none bg-transparent"
                />
              </template>
              <template #item-proceeds="item">
                R
                <input
                  v-model.lazy="item.proceeds"
                  v-number="amount"
                  class="border-none p-0 m-0 w-20 pointer-events-none bg-transparent"
                />
              </template>
              <template #item-net_profit="item">
                R
                <input
                  v-model.lazy="item.net_profit"
                  v-number="amount"
                  class="border-none p-0 m-0 w-20 pointer-events-none bg-transparent"
                />
              </template>
              <template #item-net_profit_percent="item">
                {{ item.net_profit_percent }}%
              </template>
              <template #item-statement="item">
                <button
                  id="download"
                  type="button"
                  @click.prevent="getStatement(item.url)"
                >
                  <i
                    class="fa-solid fa-download text-lg text-shiftlysunyellow"
                  ></i>
                </button>
              </template>
            </EasyDataTable>
          </div>
        </div>
        <div
          class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
        >
          <div
            class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
          >
            <p class="p-2.5 text-white font-bold">Trade Summary Profit Chart</p>
          </div>
          <div class="m-5">
            <area-chart
              :data="clientTradeData"
              suffix="%"
              :colors="['#62bcf6']"
              :curve="false"
              empty="No trade data to display, please wait for first trade."
            ></area-chart>
          </div>
        </div>
      </div>
    </VForm>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { directive as VNumber } from "@coders-tm/vue-number-format";

export default {
  name: "TransactionsForm",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    const schema = Yup.object().shape({
      period: Yup.string()
        .max(13, "Period cannot be longer than 13 characters.")
        .required("Period is required."),
      year: Yup.string().when("period", {
        is: (type) => type === "tax_year" || type === "calendar_year",
        then: Yup.string()
          .max(4, "Year cannot be longer than 4 characters.")
          .required("Year is required."),
      }),
      terms: Yup.bool().oneOf(
        [true],
        "Please agree to the Disclosure Confirmation"
      ),
    });
    const formValues = {
      period: "",
      year: "",
      terms: false,
    };
    return {
      schema,
      formValues,
      isLoading: false,
      rendered: false,
      years: this.getYears(),
      amount: {
        separator: ",",
        precision: 2,
        min: 0,
        masked: false,
      },
      client: [],
      clientTrades: [],
      clientTradeData: [],
      headers: [
        { text: "Date", value: "paid_at", width: 120 },
        { text: "Batch", value: "batch_number", width: 100 },
        { text: "FCA", value: "fca", width: 60 },
        { text: "Pin", value: "pin", width: 120 },
        { text: "Base Cost", value: "base_cost", width: 140 },
        { text: "Proceeds", value: "proceeds", width: 140 },
        { text: "Net Profit", value: "net_profit", width: 120 },
        { text: "Percentage", value: "net_profit_percent", width: 100 },
        { text: "Statement", value: "statement", width: 100 },
      ],
    };
  },
  directives: {
    number: VNumber,
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client !== "") {
        ClientService.getTradeSummary("-paid_at").then((trades) => {
          if (trades.data.length > 0) {
            this.clientTrades = trades.data;
            Object.entries(trades.data).forEach(([, value]) => {
              this.clientTradeData.push([
                value.paid_at,
                value.net_profit_percent,
              ]);
            });
          }
          this.rendered = true;
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateTradingStatus");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  created() {
    document.title = "Shiftly Client Portal - Transactions";
  },
  methods: {
    getYears() {
      const maxYear = new Date().getFullYear() + 1; // Next year
      const minYear = 2019; // Starting year
      const yearArray = [];

      for (let year = minYear; year <= maxYear; year++) {
        yearArray.push({ value: year, type: year.toString() });
      }

      return yearArray.reverse(); // Reverse the array
    },
    showSuccess(successTitle, successText) {
      this.$swal({
        icon: "success",
        title: successTitle,
        text: successText,
      });
    },
    showError(errorTitle, errorText, errorFooter) {
      this.$swal({
        icon: "error",
        title: errorTitle,
        text: errorText,
        footer: errorFooter,
      });
    },
    onSubmit(values) {
      this.isLoading = true;
      this.getTradeSummary(values);
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    //Api Submit
    getTradeSummary(values) {
      // Sending request to the backend
      ClientService.getTradeSummaryDownload(values.period, values.year)
        .then((response) => {
          var fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];
          fileName = fileName.replaceAll('"', "");
          this.downloadFile(
            response,
            fileName,
            response.headers["content-type"]
          );
          this.isLoading = false;
          if (values.period === "tax_year") {
            this.showSuccess(
              "Thank You!",
              "Your tax summary for the year " +
                values.year +
                " has been successfully downloaded."
            );
          } else if (values.period === "calendar_year") {
            this.showSuccess(
              "Thank You!",
              "Your trade summary for the year " +
                values.year +
                " has been successfully downloaded."
            );
          } else {
            this.showSuccess(
              "Thank You!",
              "Your trade summary has been successfully downloaded."
            );
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.showError(
            "Something went wrong!",
            "Please contact Shiftly support for assistance",
            '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>'
          );
          // }
        });
    },
    //Get Trading Statement
    getStatement(url) {
      ClientService.getTradingStatement(url)
        .then((response) => {
          var fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];
          fileName = fileName.replaceAll('"', "");
          this.downloadFile(
            response,
            fileName,
            response.headers["content-type"]
          );
          this.isLoading = false;
          this.showSuccess(
            "Thank You!",
            "Your trading statement has been successfully downloaded."
          );
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.showError(
            "Something went wrong!",
            "Please contact Shiftly support for assistance",
            '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>'
          );
        });
    },
    downloadFile(response, title, type) {
      var blob = new Blob([response.data], { type: type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 200);
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #ffbb10;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #ffbb10;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::file-selector-button {
  padding: 0.5rem 0.75rem;
}

.form-control::-webkit-file-upload-button {
  padding: 0.5rem 0.75rem;
}

.customize-table {
  --easy-table-border: none;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 42px;
  --easy-table-header-font-color: #0447e0;
  --easy-table-header-background-color: #ffffff;

  --easy-table-header-item-padding: 8px;

  --easy-table-body-even-row-font-color: #3c3c3d;
  --easy-table-body-even-row-background-color: #ffffff;

  --easy-table-body-row-font-color: #3c3c3d;
  --easy-table-body-row-background-color: #ffffff;
  --easy-table-body-row-height: 46px;
  --easy-table-body-row-font-size: 16px;

  --easy-table-body-row-hover-font-color: #3c3c3d;
  --easy-table-body-row-hover-background-color: transparent;

  --easy-table-body-item-padding: 8px;

  --easy-table-footer-background-color: #ffffff;
  --easy-table-footer-font-color: #3c3c3d;
  --easy-table-footer-font-size: 16px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;
}
</style>
