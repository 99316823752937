<template>
  <div>
    <div class="flex justify-center items-center pb-16">
      <div class="w-11/12 max-w-md pt-4">
        <VForm
          @submit="onSubmit"
          :validation-schema="schema"
          :initial-values="formValues"
          v-slot="{ errors }"
          @invalid-submit="onInvalidSubmit"
        >
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div id="HeaderLogo" class="w-16 mx-auto">
              <a href="https://www.shiftly.co.za">
                <img
                  alt="Shiftly Logo"
                  src="../assets/Shiftly-Logo_180x180.png"
                />
              </a>
            </div>
            <h2
              class="font-medium leading-tight text-4xl my-2.5 text-shiftlycharcoal"
            >
              Sign up with Shiftly
            </h2>
            <div class="mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Name</label
              >
              <VField
                name="client.preferred_name"
                type="text"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors['client.preferred_name'] }"
              />
              <div class="text-red-500 text-xs italic">
                {{ errors["client.preferred_name"] }}
              </div>
            </div>
            <div class="mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Surname
                <VuePopper
                  placement="top"
                  offsetDistance="4"
                  hover
                  content="Please enter your surname as per your South African ID book or card."
                  class="normal-case"
                >
                  <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                ></VuePopper>
              </label>
              <VField
                name="client.surname"
                type="text"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors['client.surname'] }"
              />
              <div class="text-red-500 text-xs italic">
                {{ errors["client.surname"] }}
              </div>
            </div>
            <div class="mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >ID Number
                <VuePopper
                  placement="top"
                  offsetDistance="4"
                  hover
                  content="Please enter your 13 digit South African ID number that can be found in your ID book or card."
                  class="normal-case"
                >
                  <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                ></VuePopper>
              </label>
              <VField
                name="client.id_number"
                type="text"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors['client.id_number'] }"
              />
              <div class="text-red-500 text-xs italic">
                {{ errors["client.id_number"] }}
              </div>
            </div>
            <div class="mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Cellphone Number</label
              >
              <VField name="cellphone_number" v-slot="{ field }">
                <vue-tel-input
                  v-model="phone"
                  defaultCountry="ZA"
                  :preferredCountries="['ZA', 'GB', 'US']"
                  v-bind="field"
                  @on-input="phoneChanged"
                  class="text-shiftlycharcoal border border-gray-200 rounded hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                  :class="{ 'border-red-500': errors.cellphone_number }"
                ></vue-tel-input>
              </VField>
              <div class="text-red-500 text-xs italic">
                {{ errors["cellphone_number"] }}
              </div>
            </div>
            <div class="mt-[18px] mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Email</label
              >
              <VField
                name="email"
                type="text"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors.email }"
              />
              <div class="text-red-500 text-xs italic">
                {{ errors.email }}
              </div>
            </div>
            <div class="mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Password
                <VuePopper
                  placement="top"
                  offsetDistance="4"
                  hover
                  class="normal-case"
                  ><template #content>
                    <p>
                      Your password should be a minimum of 8 characters and
                      include a capital letter, lowercase letter, a number and a
                      special character. <br />
                      It shouldn't be similar to any of your personal
                      information or any commonly used passwords.
                    </p>
                  </template>
                  <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                ></VuePopper>
              </label>
              <div class="relative">
                <div></div>
                <VField
                  id="password"
                  name="password"
                  type="password"
                  class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 pl-3 pr-8 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                  :class="{ 'border-red-500': errors.password }"
                >
                </VField>
                <i
                  id="eye"
                  @click.prevent="showPassword"
                  class="fa-solid fa-eye absolute right-2.5 bottom-5 text-shiftlysunyellow"
                ></i>
              </div>
              <div class="text-red-500 text-xs italic">
                {{ errors.password }}
              </div>
            </div>
            <div class="mb-2.5 text-left">
              <label
                v-if="this.$route.query.ref"
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Referral Code
                <VuePopper
                  placement="top"
                  offsetDistance="4"
                  hover
                  content="If someone already using our service has given you their 7-digit alphanumeric referral code, enter it here."
                  class="normal-case"
                >
                  <i
                    class="fa-solid fa-circle-info text-shiftlysunyellow"
                  ></i></VuePopper
              ></label>
              <label
                v-else
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Referral Code (Optional)
                <VuePopper
                  placement="top"
                  offsetDistance="4"
                  hover
                  content="If someone already using our service has given you their 7-digit alphanumeric referral code, enter it here."
                  class="normal-case"
                >
                  <i
                    class="fa-solid fa-circle-info text-shiftlysunyellow"
                  ></i></VuePopper
              ></label>
              <VField
                name="client.referred_by"
                type="text"
                :readonly="this.$route.query.ref"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors['client.referred_by'] }"
              />
              <div class="text-red-500 text-xs italic">
                {{ errors["client.referred_by"] }}
              </div>
            </div>
            <div class="form-check mb-2.5 text-left">
              <div class="flex">
                <VField
                  name="terms"
                  class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  :class="{ 'border-red-500': errors.terms }"
                  :value="true"
                  :unchecked-value="false"
                />
                <label
                  class="tracking-wide text-shiftlycharcoal text-xs font-bold mt-1"
                  for="terms"
                >
                  I have read and agree to Shiftly's Terms of Service and
                  Privacy Policy
                </label>
              </div>
              <div class="text-red-500 text-xs italic">
                {{ errors.terms }}
              </div>
            </div>
            <div class="flex items-center justify-between mt-5">
              <button
                :disabled="isLoading"
                class="w-full bg-shiftlyblue hover:bg-shiftlydarkblue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                <span v-if="isLoading">
                  <i class="fa-solid fa-spinner animate-spin"></i>
                  Signing Up
                </span>
                <span v-else>Sign Up</span>
              </button>
            </div>
            <div class="flex items-center justify-between mt-5">
              <span
                class="inline-block align-baseline font-bold text-sm text-shiftlycharcoal"
              >
                Already signed up?
                <router-link class="hover:text-shiftlysunyellow" to="/sign-in"
                  >Sign in</router-link
                >
              </span>
            </div>
          </div>
        </VForm>
      </div>
    </div>
    <button
      id="modalButtonShow"
      type="button"
      class="hidden"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
      v-if="pageLoad"
    />
    <div
      class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg relative w-auto pointer-events-none"
      >
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
        >
          <div
            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
          >
            <h5
              class="text-xl font-medium leading-normal text-shiftlycharcoal"
              id="exampleModalLabel"
            >
              Declarations
            </h5>
          </div>
          <div class="modal-body text-left relative p-4">
            <div class="form-check mb-2.5">
              <div class="flex">
                <input
                  class="h-4 w-4 border border-shiftlycharcoal rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="citizen"
                  v-model="confirmation.citizen"
                />
                <label
                  class="form-check-label text-shiftlycharcoal"
                  for="citizen"
                >
                  I am a South African citizen and have a South African ID
                  number
                </label>
              </div>
            </div>
            <div class="form-check mb-2.5">
              <div class="flex">
                <input
                  class="h-4 w-4 border border-shiftlycharcoal rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="usa"
                  v-model="confirmation.usa"
                />
                <label class="form-check-label text-shiftlycharcoal" for="usa">
                  I am not a US Citizen nor do I have any tax obligations in the
                  United States
                </label>
              </div>
            </div>
            <div class="form-check mb-2.5">
              <div class="flex">
                <input
                  class="h-4 w-4 border border-shiftlycharcoal rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="political"
                  v-model="confirmation.political"
                />
                <label
                  class="form-check-label text-shiftlycharcoal"
                  for="political"
                >
                  I am not a Politically Exposed Person
                </label>
              </div>
            </div>
            <div class="form-check mb-2.5">
              <div class="flex">
                <input
                  class="h-4 w-4 border border-shiftlycharcoal rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="capital"
                  v-model="confirmation.capital"
                />
                <label
                  class="form-check-label text-shiftlycharcoal"
                  for="capital"
                >
                  I have at least R200,000 available to invest
                </label>
              </div>
            </div>
            <div class="form-check">
              <div class="flex">
                <input
                  class="h-4 w-4 border border-shiftlycharcoal rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="tax"
                  v-model="confirmation.tax"
                />
                <label class="form-check-label text-shiftlycharcoal" for="tax">
                  I am tax compliant and have access to my SARS eFiling profile
                </label>
              </div>
            </div>
            <div class="text-red-500 text-xs italic mt-2">
              {{ confirmationError }}
            </div>
          </div>
          <div
            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md"
          >
            <button
              type="button"
              class="inline-block px-6 py-2.5 mr-2 bg-shiftlyblue text-white font-bold text-sm leading-tight rounded shadow-md hover:bg-shiftlydarkblue hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
            >
              <a href="https://www.shiftly.co.za">Return </a>
            </button>
            <button
              type="button"
              class="inline-block px-6 py-2.5 bg-shiftlyblue text-white font-bold text-sm leading-tight rounded shadow-md hover:bg-shiftlydarkblue hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
              @click.prevent="confirmSubmission"
            >
              Confirm
            </button>
            <button
              id="modalButtonHide"
              type="button"
              class="hidden"
              data-bs-dismiss="modal"
              v-if="
                confirmation.citizen &&
                confirmation.usa &&
                confirmation.political
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, configure } from "vee-validate";
import * as Yup from "yup";
import UniDecode from "unidecode-plus";
import luhn from "luhn";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

//stop cellphone_number validation on page load.
configure({
  validateOnModelUpdate: false,
});

export default {
  name: "SignUp",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    //declare Yup schema rules for VForm Validation
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Email must be a valid email address")
        .max(255, "Email cannot be longer than 255 characters.")
        .required("Email is required"),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*\d)(?=.*[@!"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required("Password is required"),
      client: Yup.object().shape({
        preferred_name: Yup.string()
          .max(50, "Preffered name cannot be longer than 50 characters.")
          .test({
            name: "preferednameValidation",
            message:
              "Preferred Name may only contain alphabetical characters, '-', '(', ')', '.' and '''",
            test: (value) => this.validateName(value),
          })
          .required("Preferred Name is required"),
        surname: Yup.string()
          .max(50, "Surname cannot be longer than 50 characters.")
          .test({
            name: "surnameValidation",
            message:
              "Surname may only contain alphabetical characters, '-', '(', ')', '.' and '''",
            test: (value) => this.validateName(value),
          })
          .required("Surname is required"),
        id_number: Yup.string()
          .length(13, "ID Number length is not valid.")
          .test({
            name: "luhnValidation",
            message: "ID Number is not valid",
            test: (value) => this.luhnCheck(value),
          })
          .required("ID Number is required"),
        referred_by: Yup.string()
          .nullable()
          .matches(
            /^[a-zA-Z0-9]+$/,
            "Referral Code may only contain alphanumeric characters."
          )
          .max(7, "Referral Code cannot be longer than 7 characters."),
      }),
      cellphone_number: Yup.string().test(
        "validPhoneNumber",
        null,
        (cellphone_number) => {
          if (cellphone_number == "") {
            return new Yup.ValidationError(
              "Cellphone Number is required",
              null,
              "cellphone_number"
            );
          } else if (cellphone_number.length > 20) {
            return new Yup.ValidationError(
              "Cellphone Number cannot be longer than 20 digits.",
              null,
              "cellphone_number"
            );
          } else if (cellphone_number.length > 0 || cellphone_number != null) {
            this.formValues.cellphone_number = cellphone_number;
            return true;
          } else {
            return new Yup.ValidationError(
              "Cellphone Number is required",
              null,
              "cellphone_number"
            );
          }
        }
      ),
      terms: Yup.bool().oneOf(
        [true],
        "Please agree to terms of service and privacy policy"
      ),
    });
    //set empty values on VForm load
    const formValues = {
      email: "",
      password: "",
      client: {
        preferred_name: "",
        surname: "",
        id_number: "",
        referred_by: this.$route.query.ref,
      },
      cellphone_number: "",
      terms: false,
    };
    return {
      schema,
      formValues,
      phone: "",
      confirmation: {
        citizen: false,
        usa: false,
        political: false,
        capital: false,
        tax: false,
      },
      pageLoad: true,
      confirmationError: "",
      isLoading: false,
      phoneObject: {},
    };
  },
  created() {
    document.title = "Shiftly Client Portal - Sign Up";
  },
  mounted() {
    //call modal with checkboxes
    if (document.getElementById("modalButtonShow")) {
      document.getElementById("modalButtonShow").click();
    }
    //stop pageload until conditions are met
    this.pageLoad = false;
    //set cellphone_number dropdown styling
    var input = document.getElementsByClassName("vti__input");
    for (var i = 0; i < input.length; i++) {
      input[i].classList.add("placeholder-shiftlycharcoal");
      input[i].classList.add("placeholder-opacity-50");
      input[i].classList.add("focus:outline-none");
      input[i].classList.add("focus:ring-0");
      input[i].classList.add("focus:border-shiftlyblue");
      input[i].classList.add("rounded");
    }
    var drop = document.getElementsByClassName("vti__dropdown");
    for (var d = 0; d < drop.length; d++) {
      drop[d].style.borderBottomLeftRadius = "4px";
      drop[d].style.borderTopLeftRadius = "4px";
    }
  },
  methods: {
    //register store actions
    ...mapActions(useApiStore, ["register"]),
    //set cellphone_number field
    phoneChanged(number, phone) {
      this.phoneObject = phone;
    },
    //check luhn validation
    luhnCheck(num) {
      return luhn.validate(num);
    },
    //method for button to show password on password field
    showPassword() {
      var x = document.getElementById("password");
      var eye = document.getElementById("eye");
      if (x.type === "password") {
        x.type = "text";
        eye.classList.remove("fa-eye");
        eye.classList.add("fa-eye-slash");
      } else {
        x.type = "password";
        eye.classList.add("fa-eye");
        eye.classList.remove("fa-eye-slash");
      }
    },
    //check if modal with checkboxes has been completed
    confirmSubmission() {
      if (
        !this.confirmation.citizen ||
        !this.confirmation.usa ||
        !this.confirmation.political ||
        !this.confirmation.capital ||
        !this.confirmation.tax
      ) {
        this.confirmationError =
          "To proceed, you must meet all the required criteria. Please review and confirm all declarations before continuing.";
      } else {
        document.getElementById("modalButtonHide").click();
      }
    },
    //method to show error
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    //method to validate name field
    validateName(value) {
      let pattern = /^[-()'. a-zA-Z]+$/;
      if (pattern.test(UniDecode(value))) {
        return true;
      } else {
        return false;
      }
    },
    //submit form values to api method
    onSubmit(values, actions) {
      this.isLoading = true;
      this.postToAPI(values, actions);
    },
    //used for development
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    //api Submit
    postToAPI(values, actions) {
      //changing phone number object in payload
      values.client.cellphone_number = this.phoneObject.number;

      //removing file objects from payload
      delete values.cellphone_number;
      delete values.terms;

      if (values.client.referred_by == "") {
        values.client.referred_by = undefined;
      }

      //actual api call to register client
      this.register(values).then(
        () => {
          this.isLoading = false;
          this.$swal(
            "Thank you for signing up!",
            "Please check your email for further instructions.",
            "info"
          );
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                if (error.response.data[key].constructor == Object) {
                  for (var nestedKey in error.response.data[key]) {
                    var nestedErrorMessage = "";
                    for (var nestedError in error.response.data[key][
                      nestedKey
                    ]) {
                      nestedErrorMessage +=
                        error.response.data[key][nestedKey][nestedError] + " ";
                    }
                    if (nestedKey == "cellphone_number") {
                      this.isLoading = false;
                      actions.setFieldError(nestedKey, nestedErrorMessage);
                    } else {
                      this.isLoading = false;
                      actions.setFieldError(
                        [key] + "." + [nestedKey],
                        nestedErrorMessage
                      );
                    }
                  }
                } else {
                  var errorMessage = "";
                  for (var flatError in error.response.data[key]) {
                    errorMessage += error.response.data[key][flatError] + " ";
                  }
                  this.isLoading = false;
                  actions.setFieldError(key, errorMessage);
                }
              } else if (
                error.response.data[key] == "Unable to create account."
              ) {
                this.isLoading = false;
                actions.setFieldError(
                  "email",
                  "user with this Email already exists."
                );
              } else if (
                error.response.data[key] ==
                "Client with this ID number already exists."
              ) {
                this.isLoading = false;
                actions.setFieldError(
                  "client.id_number",
                  "Client with this ID number already exists."
                );
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #ffbb10;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #ffbb10;
}
</style>
