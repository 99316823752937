<template>
  <div class="flex justify-center items-center pb-16">
    <div class="w-11/12 max-w-sm">
      <VForm
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="formValues"
        v-slot="{ errors }"
        @invalid-submit="onInvalidSubmit"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div id="HeaderLogo" class="w-16 mx-auto">
          <a href="https://www.shiftly.co.za">
            <img alt="Shiftly Logo" src="/Shiftly-Logo_180x180.png" />
          </a>
        </div>
        <h2
          class="font-medium leading-tight text-4xl my-2.5 text-shiftlycharcoal"
        >
          Reset password
        </h2>
        <div>
          <div class="mb-4">
            <span class="px-2 mb-2.5 text-left text-shiftlycharcoal"
              >Please enter and confirm your new password.
            </span>
          </div>
          <div class="mb-4 text-left">
            <label
              class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
              >Password
              <VuePopper
                placement="top"
                offsetDistance="4"
                hover
                class="normal-case"
                ><template #content>
                  <p>
                    Your password should be a minimum of 8 characters and
                    include a capital letter, lowercase letter, a number and a
                    special character. <br />
                    It shouldn't be similar to any of your personal information
                    or any commonly used passwords.
                  </p>
                </template>
                <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
              ></VuePopper>
            </label>
            <div class="relative">
              <VField
                id="new_password"
                name="new_password"
                type="password"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 pl-3 pr-8 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors.new_password }"
              >
              </VField>
              <i
                id="eye"
                @click.prevent="showPassword"
                class="fa-solid fa-eye absolute right-2.5 bottom-5 text-shiftlysunyellow"
              ></i>
            </div>
            <div class="text-red-500 text-xs italic">
              {{ errors.new_password }}
            </div>
          </div>
          <div class="mb-4 text-left">
            <label
              class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
              >Confirm Password</label
            >
            <div class="relative">
              <VField
                id="confirm_password"
                name="confirm_password"
                type="password"
                class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 pl-3 pr-8 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
                :class="{ 'border-red-500': errors.confirm_password }"
              >
              </VField>
              <i
                id="confirm_eye"
                @click.prevent="showConfirmPassword"
                class="fa-solid fa-eye absolute right-2.5 bottom-5 text-shiftlysunyellow"
              ></i>
            </div>
            <div class="text-red-500 text-xs italic">
              {{ errors.confirm_password }}
            </div>
          </div>
          <div class="flex items-center justify-between mt-5">
            <button
              :disabled="isLoading"
              class="w-full bg-shiftlyblue hover:bg-shiftlydarkblue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              <span v-if="isLoading">
                <i class="fa-solid fa-spinner animate-spin"></i>
                Resetting Password
              </span>
              <span v-else>Reset Password</span>
            </button>
          </div>
        </div>
      </VForm>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

export default {
  name: "ResetPassword",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    const schema = Yup.object().shape({
      new_password: Yup.string()
        .matches(
          /^(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*\d)(?=.*[@!"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required("Please enter your New Password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Please confirm your New Password"),
    });
    const formValues = {
      new_password: "",
      confirm_password: "",
    };
    return {
      isLoading: false,
      schema,
      formValues,
    };
  },
  created() {
    document.title = "Shiftly Client Portal - Reset Password";
  },
  methods: {
    ...mapActions(useApiStore, ["resetPasswordConfirm"]),
    showPassword() {
      var x = document.getElementById("new_password");
      var eye = document.getElementById("eye");
      if (x.type === "password") {
        x.type = "text";
        eye.classList.remove("fa-eye");
        eye.classList.add("fa-eye-slash");
      } else {
        x.type = "password";
        eye.classList.add("fa-eye");
        eye.classList.remove("fa-eye-slash");
      }
    },
    showConfirmPassword() {
      var x = document.getElementById("confirm_password");
      var eye = document.getElementById("confirm_eye");
      if (x.type === "password") {
        x.type = "text";
        eye.classList.remove("fa-eye");
        eye.classList.add("fa-eye-slash");
      } else {
        x.type = "password";
        eye.classList.add("fa-eye");
        eye.classList.remove("fa-eye-slash");
      }
    },
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance.",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    onSubmit(values, actions) {
      this.isLoading = true;
      this.postToAPI(values, actions);
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    //Api Submit
    postToAPI(values, actions) {
      //adding uid and token to values
      values.uid = this.$route.query.uid;
      values.token = this.$route.query.token;

      //removing file objects from payload
      delete values.confirm_password;

      this.resetPasswordConfirm(values).then(
        () => {
          this.isLoading = false;
          this.$swal(
            "Your Password Reset was Successful",
            "Please continue to sign in.",
            "success"
          );
          this.$router.push("/sign-in");
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                var errorMessage = "";
                for (var flatError in error.response.data[key]) {
                  errorMessage += error.response.data[key][flatError] + " ";
                }
                this.isLoading = false;
                actions.setFieldError(key, errorMessage);
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #ffbb10;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #ffbb10;
}
</style>
