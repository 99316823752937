<template>
  <div class="flex justify-center items-center pb-16">
    <div class="w-11/12 max-w-sm">
      <VForm
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="formValues"
        v-slot="{ errors }"
        @invalid-submit="onInvalidSubmit"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div id="HeaderLogo" class="w-16 mx-auto">
          <a href="https://www.shiftly.co.za">
            <img alt="Shiftly Logo" src="../assets/Shiftly-Logo_180x180.png" />
          </a>
        </div>
        <h2
          class="font-medium leading-tight text-4xl my-2.5 text-shiftlycharcoal"
        >
          Sign in to Shiftly
        </h2>
        <div class="mb-4 text-left">
          <label
            class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
            >Email</label
          >
          <VField
            name="email"
            type="text"
            class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
            :class="{ 'border-red-500': errors.email }"
          />
          <div class="text-red-500 text-xs italic">
            {{ errors.email }}
          </div>
        </div>

        <div class="mb-4 text-left">
          <label
            class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
            >Password</label
          >
          <div class="relative">
            <VField
              id="password"
              name="password"
              type="password"
              class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 pl-3 pr-8 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
              :class="{ 'border-red-500': errors.password }"
            >
            </VField>
            <i
              id="eye"
              @click.prevent="showPassword"
              class="fa-solid fa-eye absolute right-2.5 bottom-5 text-shiftlysunyellow"
            ></i>
          </div>
          <div class="text-red-500 text-xs italic">
            {{ errors.password }}
          </div>
        </div>
        <div class="text-red-500 text-xs italic mt-4">
          {{ signInError }}
        </div>
        <div class="flex items-center justify-between mt-5">
          <button
            :disabled="isLoading"
            class="w-full bg-shiftlyblue hover:bg-shiftlydarkblue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            <span v-if="isLoading">
              <i class="fa-solid fa-spinner animate-spin"></i>
              Signing In
            </span>
            <span v-else>Sign In</span>
          </button>
        </div>
        <div class="flex items-center justify-between mt-5">
          <router-link
            class="inline-block align-baseline font-bold text-sm text-shiftlycharcoal hover:text-shiftlysunyellow"
            to="/forgot-password"
          >
            Forgot password?
          </router-link>
          <router-link
            class="inline-block align-baseline font-bold text-sm text-shiftlycharcoal hover:text-shiftlysunyellow"
            to="/"
          >
            Sign up now
          </router-link>
        </div>
      </VForm>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ClientService from "../services/client.service";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

export default {
  name: "SignIn",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Email must be a valid email address")
        .max(255, "Email cannot be longer than 255 characters.")
        .required("Email is required"),
      password: Yup.string().required("Please Enter your password"),
    });
    const formValues = {
      email: "",
      password: "",
    };
    return {
      schema,
      formValues,
      activation: {
        uid: "",
        token: "",
      },
      referral: {
        ref: "",
        mac: "",
      },
      refcid: "",
      signInError: "",
      isLoading: false,
    };
  },
  created() {
    document.title = "Shiftly Client Portal - Sign In";
  },
  mounted() {
    if (this.$route.query.uid != null && this.$route.query.token != null) {
      this.activation.uid = this.$route.query.uid;
      this.activation.token = this.$route.query.token;

      this.registerConfirm(this.activation).then(
        () => {
          this.$swal(
            "Thank you for confirming your email!",
            "Please continue to sign in.",
            "success"
          );
        },
        (error) => {
          if (error.response && error.response.status == "400") {
            for (var key in error.response.data) {
              for (var flatError in error.response.data[key]) {
                if (
                  error.response.data[key][flatError] ==
                  "Invalid token for given user."
                ) {
                  this.isLoading = false;
                  this.showVerificationError(error);
                }
              }
            }
          } else {
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    } else if (
      this.$route.query.ref != null &&
      this.$route.query.cid != null &&
      this.$route.query.mac != null
    ) {
      this.referral.ref = this.$route.query.ref;
      this.refcid = this.$route.query.cid;
      this.referral.mac = this.$route.query.mac;

      var payload = { cid: this.refcid, values: this.referral };

      this.referralConfirm(payload).then(
        () => {
          this.$swal({
            icon: "success",
            title: "Referral invite confirmed!",
            html:
              "Please continue to sign in and select the Premium service option." +
              "<br /><br />" +
              "If you have not signed in before click 'Forgot password' and reset your password with the email you originally used to signed up to Shiftly.",
          });
        },
        (error) => {
          this.showError(error);
        }
      );
    }
  },
  methods: {
    ...mapActions(useApiStore, ["registerConfirm", "login", "referralConfirm"]),
    showPassword() {
      var x = document.getElementById("password");
      var eye = document.getElementById("eye");
      if (x.type === "password") {
        x.type = "text";
        eye.classList.remove("fa-eye");
        eye.classList.add("fa-eye-slash");
      } else {
        x.type = "password";
        eye.classList.add("fa-eye");
        eye.classList.remove("fa-eye-slash");
      }
    },
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    showVerificationError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Verification Link has Expired!",
        text: "Please contact Shiftly support for assistance",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    onSubmit(values, actions) {
      this.isLoading = true;
      this.postToAPI(values, actions);
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    getClientUrl() {
      ClientService.getClientUrl().then(
        () => {
          this.isLoading = false;
          this.$router.push("/home");
        },
        (error) => {
          this.isLoading = false;
          this.showError(error);
        }
      );
    },
    //Api Submit
    postToAPI(values, actions) {
      this.login(values).then(
        () => {
          this.getClientUrl();
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                var errorMessage = "";
                for (var flatError in error.response.data[key]) {
                  errorMessage += error.response.data[key][flatError] + " ";
                }
                this.isLoading = false;
                actions.setFieldError(key, errorMessage);
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            if (
              error.response &&
              error.response.status == "401" &&
              error.response.data.detail ==
                "No active account found with the given credentials"
            ) {
              this.isLoading = false;
              this.signInError = "Email and/or Password is not correct.";
            } else {
              this.isLoading = false;
              this.showError(error);
            }
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
