<template>
  <div v-if="rendered">
    <div class="pt-10 flex flex-col">
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div class="flex">
          <i
            class="py-3.5 pl-2 fa-solid fa-circle-info text-shiftlysunyellow"
          ></i>
          <p class="py-2.5 px-2 text-shiftlycharcoal">
            Your approved trade size is R<vue-number
              v-model="this.client.trade_size"
              v-number="balance"
              class="w-16 border-none text-base p-0 m-0 pointer-events-none bg-transparent"
            ></vue-number
            >per trade. If you’d like to increase this, please
            <a
              class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href="https://www.shiftly.co.za/contact-support/"
              target="_blank"
              >contact</a
            >
            our support team.
          </p>
        </div>
      </div>
    </div>
    <h2
      class="container mx-auto w-11/12 text-lg text-left font-bold mb-2 pt-8 text-shiftlycharcoal"
    >
      Deposit
    </h2>
    <h3
      class="container mx-auto w-11/12 text-md text-left text-shiftlycharcoal"
    >
      Please deposit <span class="font-bold">R</span>
      <vue-number
        v-model="this.depositInfo.amount"
        v-number="balance"
        class="w-16 border-none text-base font-bold p-0 m-0 pointer-events-none bg-transparent"
      ></vue-number
      >(R<vue-number
        v-model="this.client.trade_size"
        v-number="balance"
        class="w-16 border-none text-base p-0 m-0 pointer-events-none bg-transparent"
      ></vue-number
      >trading capital + R500 bank fee) using the details below.
    </h3>
    <div class="py-10 flex flex-col">
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div class="lg:flex flex-wrap justify-center" style="cursor: auto">
          <div class="relative rounded-lg h-auto lg:w-5/12 m-5">
            <div class="flex">
              <label
                class="w-1/2 text-left tracking-wide text-shiftlycharcoal font-bold"
                >Account Name:
              </label>
              <span>{{
                this.client.initials + " " + this.client.surname
              }}</span>
            </div>
            <div class="flex mt-5">
              <label
                class="w-1/2 text-left tracking-wide text-shiftlycharcoal font-bold"
                >Bank Name:
              </label>
              <span>{{ this.activeTradingAccount.bankName }}</span>
            </div>
            <div class="flex mt-5">
              <label
                class="w-1/2 text-left tracking-wide text-shiftlycharcoal font-bold"
                >Branch Code:
              </label>
              <span>{{ this.activeTradingAccount.branchCode }}</span>
            </div>
          </div>
          <div class="relative rounded-lg h-auto lg:w-5/12 m-5">
            <div class="flex">
              <label
                class="w-1/2 text-left tracking-wide text-shiftlycharcoal font-bold"
                >Account Number:
              </label>
              <span>{{ this.activeTradingAccount.account_number }}</span>
            </div>
            <div class="flex mt-5">
              <label
                class="w-1/2 text-left tracking-wide text-shiftlycharcoal font-bold"
                >Account Type:
              </label>
              <span>{{ this.activeTradingAccount.account_type }}</span>
            </div>
            <div class="flex mt-5">
              <label
                class="w-1/2 text-left tracking-wide text-shiftlycharcoal font-bold"
                >Deposit Reference:
              </label>
              <span class="bg-shiftlybackground2 px-2">
                {{ this.client.deposit_reference }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VForm
      ref="form"
      @submit="onSubmit"
      :validation-schema="schema"
      :initial-values="formValues"
      v-slot="{ errors }"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="pb-10 flex flex-col">
        <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
          <div
            class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 rounded-t-lg shadow-md"
          >
            <p class="p-2.5 text-white font-bold">Deposit Details</p>
          </div>
          <div class="mx-3 mt-5 md:flex">
            <div class="md:w-6/12 px-2 mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Deposit Amount
                <VuePopper
                  placement="top"
                  offsetDistance="4"
                  hover
                  content="Your deposit amount in Rand including the R500 SWIFT fee."
                  class="normal-case"
                >
                  <i class="fa-solid fa-circle-info text-shiftlysunyellow"></i
                ></VuePopper>
              </label>
              <VField name="deposit_amount" v-slot="{ field }">
                <input
                  v-number="amount_number"
                  v-bind="field"
                  class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                  :class="{ 'border-red-500': errors.deposit_amount }"
              /></VField>
              <div class="text-red-500 text-xs italic">
                {{ errors.deposit_amount }}
              </div>
            </div>
          </div>
          <div class="mx-3 md:flex">
            <div class="md:w-6/12 px-2 mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Upload PDF Transaction History</label
              >
              <VField name="tx_history" v-slot="{ handleChange, handleBlur }">
                <input
                  id="tx_history"
                  type="file"
                  accept=".pdf, application/pdf"
                  multiple
                  class="form-control block w-full px-3 py-1.5 mb-2 text-base font-normal text-shiftlycharcoal bg-white bg-clip-padding border border-solid border-gray-200 rounded transition ease-in-out hover:border-shiftlyblue focus:outline-none focus:ring-0 focus:border-shiftlyblue"
                  :class="{ 'border-red-500': errors.tx_history }"
                  @change="handleChange"
                  @blur="handleBlur"
                />
              </VField>
              <div class="text-red-500 text-xs italic">
                {{ errors.tx_history }}
              </div>
              <p class="text-sm text-gray-600 mb-1.5">
                Please upload a PDF transaction history for every account the
                funds moved through until reaching your trading account.
              </p>
              <div class="text-sm text-gray-600">
                <ul class="list-disc">
                  <li class="tracking-wide mt-1 ml-4">
                    For example, if you moved money from your home loan → cheque
                    account → trading account, upload histories for both the
                    <strong>home loan and cheque accounts</strong>.
                  </li>
                  <li class="tracking-wide mt-1 ml-4">
                    The bank wants to be able to trace the full path of the
                    funds.
                  </li>
                </ul>
              </div>
              <p class="mb-1 text-sm text-gray-600 mt-1.5">
                Ensure your name appears on each document. Password-protected
                PDFs will not upload successfully.
                <br />
              </p>
            </div>
          </div>
          <div class="mx-3 md:flex">
            <div class="md:w-12/12 px-2 mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Please Note:</label
              >
              <ul class="list-disc">
                <li
                  class="tracking-wide text-shiftlycharcoal text-sm mt-1 ml-4"
                >
                  Proof of payment documents and screenshots are
                  <strong>not accepted</strong>.
                </li>
                <li
                  class="tracking-wide text-shiftlycharcoal text-sm mt-1 ml-4"
                >
                  <strong>Do not make multiple smaller deposits.</strong> Please
                  aggregate your funds in your own account and make a single
                  deposit. Ensure you upload transaction histories for all
                  accounts involved.
                </li>
                <li
                  class="tracking-wide text-shiftlycharcoal text-sm mt-1 ml-4"
                >
                  If your name does not appear on the transaction history, also
                  upload a recent bank statement or confirmation letter.
                </li>
                <li
                  class="tracking-wide text-shiftlycharcoal text-sm mt-2 ml-4"
                >
                  Using an incorrect reference may cause delays in processing
                  your deposit.
                </li>
              </ul>
            </div>
          </div>
          <div class="mx-3 mt-5 md:flex">
            <div class="md:w-12/12 px-2 mb-2.5 text-left">
              <label
                class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
                >Disclosure Confirmation</label
              >
              <div class="flex">
                <VField
                  name="terms"
                  class="h-4 w-4 border border-gray-200 rounded-sm bg-white text-shiftlyblue focus:ring-1 focus:ring-shiftlyblue focus:border-transparent transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  type="checkbox"
                  :class="{ 'border-red-500': errors.terms }"
                  :value="true"
                  :unchecked-value="false"
                />
                <label
                  class="tracking-wide text-shiftlycharcoal text-sm mt-1"
                  for="terms"
                >
                  I acknowledge and understand the above requirements for
                  uploading my transaction history and using the correct deposit
                  reference.
                </label>
              </div>
              <div class="text-red-500 text-xs italic">
                {{ errors.terms }}
              </div>
            </div>
          </div>
          <div class="mx-3 flex mt-2">
            <div class="w-6/12">
              <router-link to="/home">
                <button
                  type="button"
                  class="w-28 py-4 px-8 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                >
                  Cancel
                </button>
              </router-link>
            </div>
            <div class="w-6/12">
              <button
                :disabled="isLoading"
                type="submit"
                class="w-28 py-4 my-5 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                :class="[isLoading ? 'px-1' : 'px-8']"
              >
                <span v-if="isLoading">
                  <i class="fa-solid fa-spinner animate-spin"></i>
                  Submitting
                </span>
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </div>
        <div
          class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
        >
          <div
            class="block bg-gradient-to-r from-shiftlyblue to-shiftlyskyblue text-white h-11 shadow-md rounded-t-lg"
          >
            <p class="p-2.5 text-white font-bold">Deposit History</p>
          </div>
          <div class="mx-3 mt-5 md:flex">
            <span class="px-2 mb-2.5 text-left text-shiftlycharcoal">
              Please see your deposit history displayed below.
            </span>
          </div>
          <div class="mx-auto w-11/12">
            <EasyDataTable
              :headers="headers"
              :items="clientDeposits"
              :rows-items="[25, 50, 100]"
              :rows-per-page="10"
              theme-color="#0447e0"
              table-class-name="customize-table"
              header-text-direction="left"
              body-text-direction="left"
            >
              <template #empty-message>
                <span class="text-shiftlycharcoal text-base"
                  >No deposit data to display, please wait for first
                  deposit.</span
                >
              </template>
              <template #item-amount="item">
                <span v-if="item.amount != null">R </span>
                <input
                  v-model.lazy="item.amount"
                  v-number="amount_deposit"
                  class="border-none p-0 m-0 w-20 pointer-events-none bg-transparent"
                />
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
    </VForm>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import { mapActions, mapState } from "pinia";
import { useApiStore } from "../stores/apiStore";
import {
  directive as VNumber,
  component as VueNumber,
} from "@coders-tm/vue-number-format";
import moment from "moment";
import { capitalize } from "vue";

export default {
  name: "DepositForm",
  components: {
    VForm: Form,
    VField: Field,
    VueNumber,
  },
  data() {
    const schema = Yup.object().shape({
      deposit_amount: Yup.string().required("Deposit Amount is required"),
      tx_history: Yup.array().test("uploadValid", null, (tx_history) => {
        if (tx_history.length > 0) {
          if (!this.checkFile(tx_history[0])) {
            return new Yup.ValidationError(
              "Only the following format is accepted: .pdf",
              null,
              "tx_history"
            );
          } else {
            return true;
          }
        } else {
          return new Yup.ValidationError(
            "Transaction History is Required",
            null,
            "tx_history"
          );
        }
      }),
      terms: Yup.bool().oneOf(
        [true],
        "Please agree to the Disclosure Confirmation"
      ),
    });
    const formValues = {
      deposit_amount: 0,
      tx_history: [],
      terms: false,
    };
    return {
      schema,
      formValues,
      isLoading: false,
      amount_number: {
        separator: ",",
        precision: 2,
        min: 0,
        max: 10000000,
        masked: false,
      },
      balance: {
        separator: ",",
        precision: 2,
        min: 0,
        masked: false,
      },
      amount_deposit: {
        separator: ",",
        precision: 2,
        min: 0,
        masked: false,
      },
      rendered: false,
      depositInfo: {
        amount: 0,
        bank_account: "",
        tx_history: "",
      },
      activeTradingAccount: [],
      fxBankAccounts: [],
      client: [],
      clientBanks: [],
      clientDeposits: [],
      headers: [
        { text: "Date", value: "created_at", width: 110 },
        { text: "Bank Account", value: "bank_account", width: 350 },
        { text: "Amount", value: "amount", width: 140 },
        { text: "Status", value: "status", width: 140 },
      ],
      fullUserName: localStorage.getItem("fullUserName"),
    };
  },
  directives: {
    number: VNumber,
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      this.clientBanks = this.banks.slice();
      if (this.client !== "") {
        this.formValues.deposit_amount = this.client.trade_size + 500;
        this.depositInfo.amount = this.client.trade_size + 500;
        this.getClientBankAccounts().then((result) => {
          if (result) {
            this.getClientDeposits().then((result) => {
              if (result) {
                this.rendered = true;
              }
            });
          }
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateTradingStatus");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  created() {
    document.title = "Shiftly Client Portal - Deposit";
  },
  computed: {
    ...mapState(useApiStore, {
      banks: "banks",
    }),
  },
  methods: {
    ...mapActions(useApiStore, [
      "postArbitrageDepositRequest",
      "patchSourceofFunds",
      "postSourceofFunds",
    ]),
    showError(errorTitle, errorText, errorFooter) {
      this.$swal({
        icon: "error",
        title: errorTitle,
        text: errorText,
        footer: errorFooter,
      });
    },
    showSuccess() {
      this.$swal(
        "Thank You!",
        "We have received your deposit intent",
        "success"
      );
    },
    checkFile(file) {
      const types = ["application/pdf"];
      if (types.includes(file.type)) {
        return true;
      } else {
        return false;
      }
    },
    getClientBankAccounts() {
      this.fxBankAccounts = [];
      return new Promise((resolve) => {
        ClientService.getClientBankAccountsOrdering("created_at").then(
          (bankAccounts) => {
            if (bankAccounts.data.count > 0) {
              Object.entries(bankAccounts.data.results).forEach(
                ([key, value]) => {
                  if (
                    value.intermediary != null &&
                    value.status == "verified"
                  ) {
                    this.fxBankAccounts.push(bankAccounts.data.results[key]);
                  }

                  if (value.url == this.client.active_trading_account) {
                    this.activeTradingAccount = bankAccounts.data.results[key];
                  }
                }
              );
              if (this.fxBankAccounts.length > 0) {
                Object.entries(this.fxBankAccounts).forEach(([key, value]) => {
                  for (key in this.clientBanks) {
                    if (this.clientBanks[key].url == value.bank) {
                      value.bankName = this.clientBanks[key].display_name;
                      value.branchCode =
                        this.clientBanks[key].universal_branch_code;
                    }
                  }
                  value.account_type =
                    value.account_type.charAt(0).toUpperCase() +
                    value.account_type.slice(1);
                  value.display_name =
                    value.bankName +
                    " - " +
                    value.account_type +
                    " - " +
                    value.account_number;
                });
              }
            }

            if (
              this.fxBankAccounts.length > 0 &&
              this.activeTradingAccount != ""
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      });
    },
    getClientDeposits() {
      this.clientDeposits = [];
      return new Promise((resolve) => {
        ClientService.getArbitrageDeposits().then((deposits) => {
          if (deposits.data.count > 0) {
            this.clientDeposits = this.transformData(deposits.data.results);
          }
        });
        resolve(true);
      });
    },
    transformData(data) {
      const deposits = [];

      Object.entries(data).forEach(([, value]) => {
        var deposit = {
          created_at: "",
          bank_account: "",
          amount: "",
          status: "",
        };

        deposit.created_at = moment(value.created_at).format("YYYY-MM-DD");
        deposit.amount = value.amount;
        deposit.status =
          value.status == "verify_failed"
            ? "Verify Failed"
            : capitalize(value.status);

        Object.entries(this.fxBankAccounts).forEach(([, item]) => {
          if (item.url == value.bank_account) {
            deposit.bank_account = item.display_name;
          }
        });

        deposits.push(deposit);
      });
      return deposits;
    },
    onSubmit(values, actions) {
      this.isLoading = true;

      this.postTXHistory(
        "transaction_history",
        "transaction_history",
        values.tx_history[0]
      )
        .then((tx_response) => {
          this.depositInfo.tx_history = tx_response.url;
          if (values.tx_history.length > 1) {
            var files = values.tx_history.slice(1);
            this.patchTXHistory(
              "transaction_history",
              "transaction_history",
              files,
              tx_response.url
            )
              .then(() => {
                this.postToBackend(values);
              })
              .catch((error) => {
                console.log(error);
                actions.setFieldError(
                  "tx_history",
                  "Error with upload, please try again."
                );
              });
          } else {
            this.postToBackend(values);
          }
        })
        .catch((error) => {
          console.log(error);
          actions.setFieldError(
            "tx_history",
            "Error with upload, please try again."
          );
        });
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    postTXHistory(category, type, file) {
      //Build up FormData
      const formData = new FormData();
      formData.append("category", category);
      formData.append("type", type);
      formData.append("file", file);

      //sending payload to the backend
      return new Promise((resolve, reject) => {
        this.postSourceofFunds(formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status == "400") {
              for (var key in error.response.data) {
                if (
                  error.response.data[key] ==
                  "Document with this Client, Category, Type and Md5 hash already exists."
                ) {
                  ClientService.getDocumentsCategoryOrdering(
                    "transaction_history",
                    "-created_at"
                  ).then((txHistoryDocuments) => {
                    if (txHistoryDocuments.data.count > 0) {
                      resolve(txHistoryDocuments.data.results[0]);
                    }
                  });
                } else {
                  this.isLoading = false;
                  reject(error);
                }
              }
            } else {
              this.isLoading = false;
              this.showError(error);
            }
          });
      });
    },
    patchTXHistory(category, type, files, documentUrl) {
      const fileKeys = Object.keys(files);

      // Initialize a Promise chain
      return fileKeys.reduce((promiseChain, key) => {
        return promiseChain.then((results) => {
          // Build FormData for the current file
          const formData = new FormData();
          formData.append("category", category);
          formData.append("type", type);
          formData.append("file", files[key]);

          const payload = { url: documentUrl, values: formData };

          // Add the next promise to the chain
          return this.patchSourceofFunds(payload)
            .then((response) => {
              results.push(response); // Collect the result
              return results; // Pass results to the next iteration
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response?.status === 400) {
                // Specific error handling
                return Promise.reject(error);
              } else {
                this.showError(error);
                return Promise.reject(error); // Stop the chain on error
              }
            });
        });
      }, Promise.resolve([])); // Start with an empty array of results
    },
    //Api Submit
    postToBackend(values) {
      //adding active trading account to depositInfo
      this.depositInfo.bank_account = this.activeTradingAccount.url;

      //parsing deposit amount to number
      if (isNaN(values.deposit_amount)) {
        if (!isNaN(Number(values.deposit_amount.replace(",", "")))) {
          this.depositInfo.amount = Number(
            values.deposit_amount.replace(",", "")
          );
        } else {
          var newAmount = values.deposit_amount.replace(",", "");
          if (!isNaN(Number(newAmount.replace(",", "")))) {
            this.depositInfo.amount = Number(newAmount.replace(",", ""));
          }
        }
      }

      var depositPayload = { values: this.depositInfo };

      // Sending payload to the backend
      this.postArbitrageDepositRequest(depositPayload).then(
        () => {
          this.isLoading = false;
          this.showSuccess();
          this.$router.push("/home");
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showError(
            "Something went wrong!",
            "Please contact Shiftly support for assistance",
            '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>'
          );
        }
      );
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  max-width: 220px;
}

:deep(.popper #arrow::before) {
  background: #ffbb10;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #ffbb10;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::file-selector-button {
  padding: 0.5rem 0.75rem;
}

.form-control::-webkit-file-upload-button {
  padding: 0.5rem 0.75rem;
}

.customize-table {
  --easy-table-border: none;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 42px;
  --easy-table-header-font-color: #0447e0;
  --easy-table-header-background-color: #ffffff;

  --easy-table-header-item-padding: 8px;

  --easy-table-body-even-row-font-color: #3c3c3d;
  --easy-table-body-even-row-background-color: #ffffff;

  --easy-table-body-row-font-color: #3c3c3d;
  --easy-table-body-row-background-color: #ffffff;
  --easy-table-body-row-height: 46px;
  --easy-table-body-row-font-size: 16px;

  --easy-table-body-row-hover-font-color: #3c3c3d;
  --easy-table-body-row-hover-background-color: transparent;

  --easy-table-body-item-padding: 8px;

  --easy-table-footer-background-color: #ffffff;
  --easy-table-footer-font-color: #3c3c3d;
  --easy-table-footer-font-size: 16px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;
}
</style>
