<template>
  <div class="flex justify-center items-center pb-16">
    <div class="w-11/12 max-w-sm">
      <VForm
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="formValues"
        v-slot="{ errors }"
        @invalid-submit="onInvalidSubmit"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div id="HeaderLogo" class="w-16 mx-auto">
          <a href="https://www.shiftly.co.za">
            <img alt="Shiftly Logo" src="../assets/Shiftly-Logo_180x180.png" />
          </a>
        </div>
        <h2
          class="font-medium leading-tight text-4xl my-2.5 text-shiftlycharcoal"
        >
          Reset password
        </h2>
        <div v-if="!this.emailSent">
          <div class="mb-4">
            <span class="px-2 mb-2.5 text-left text-shiftlycharcoal"
              >Please enter your email address and we'll send you a link to
              reset your password.
            </span>
          </div>
          <div class="mb-4 text-left">
            <label
              class="uppercase tracking-wide text-shiftlyblue text-xs font-bold mb-2"
              >Email</label
            >
            <VField
              name="email"
              type="text"
              :readonly="this.$route.query.email"
              class="w-full bg-white text-shiftlycharcoal border border-gray-200 rounded py-2 px-3 mb-2 hover:border-shiftlyblue focus:outline-none focus:ring-1 focus:ring-shiftlyblue focus:border-transparent"
              :class="{ 'border-red-500': errors.email }"
            />
            <div class="text-red-500 text-xs italic">
              {{ errors.email }}
            </div>
          </div>
          <div class="flex items-center justify-between mt-5">
            <button
              :disabled="isLoading"
              class="w-full bg-shiftlyblue hover:bg-shiftlydarkblue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              <span v-if="isLoading">
                <i class="fa-solid fa-spinner animate-spin"></i>
                Resetting Password
              </span>
              <span v-else>Reset Password</span>
            </button>
          </div>
        </div>
        <div v-if="this.emailSent" class="mb-4">
          <span class="px-2 mb-2.5 text-left text-shiftlycharcoal"
            >Please check your email for password reset link.
          </span>
        </div>
      </VForm>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

export default {
  name: "ForgotPassword",
  components: {
    VForm: Form,
    VField: Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Email must be a valid email address")
        .max(255, "Email cannot be longer than 255 characters.")
        .required("Email is required"),
    });
    const formValues = {
      email: this.$route.query.email,
    };
    return {
      schema,
      formValues,
      emailSent: false,
      isLoading: false,
    };
  },
  created() {
    document.title = "Shiftly Client Portal - Forgot Password";
  },
  methods: {
    ...mapActions(useApiStore, ["resetPassword"]),
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    onSubmit(values, actions) {
      this.isLoading = true;
      this.postToAPI(values, actions);
    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    //Api Submit
    postToAPI(values, actions) {
      this.resetPassword(values).then(
        () => {
          this.isLoading = false;
          this.$swal(
            "Request for password reset has been sent.",
            "Please check your email for further instructions.",
            "info"
          );
        },
        (error) => {
          if (error.response.status == "400") {
            for (var key in error.response.data) {
              if (Object.prototype.hasOwnProperty.call(this.formValues, key)) {
                var errorMessage = "";
                for (var flatError in error.response.data[key]) {
                  errorMessage += error.response.data[key][flatError] + " ";
                }
                this.isLoading = false;
                actions.setFieldError(key, errorMessage);
              } else {
                this.isLoading = false;
                this.showError(error);
              }
            }
          } else {
            this.isLoading = false;
            this.showError(error);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
