import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import SignUp from "../views/SignUp.vue";
import Reset from "../components/Reset.vue";
import Deposit from "../views/Deposit.vue";
import Trade from "../views/Trade.vue";
import Transactions from "../views/Transactions.vue";
import Capitec from "../views/Capitec.vue";
import AITApplications from "../views/AITApplications.vue";
import Referrals from "../views/Referrals.vue";
import TradeContinuation from "../views/TradeContinuation.vue";
import jwt_decode from "jwt-decode";
import Withdraw from "../views/Withdraw.vue";

const routes = [
  {
    path: "/",
    name: "SignUp",
    component: SignUp,
    props: (route) => ({ query: route.query.ref }),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    props: (route) => (
      { query: route.query.uid },
      { query: route.query.token },
      { query: route.query.ref },
      { query: route.query.cid },
      { query: route.query.mac }
    ),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    props: (route) => ({ query: route.query.email }),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/forgot-password/reset",
    name: "ResetPassword",
    component: Reset,
    props: (route) => (
      { query: route.query.uid }, { query: route.query.token }
    ),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile",
    name: "Profile",
    // redirect: "/home",
    component: Profile,
    children: [
      {
        path: "personal-information",
        component: () => import("../components/Personal.vue"),
      },
      {
        path: "contact-details",
        component: () => import("../components/Contact.vue"),
      },
      {
        path: "fica-documents",
        component: () => import("../components/Fica.vue"),
      },
      {
        path: "employment-information",
        component: () => import("../components/Employment.vue"),
      },
      {
        path: "source-of-funds",
        component: () => import("../components/Source.vue"),
      },
      {
        path: "efiling-information",
        component: () => import("../components/EFiling.vue"),
      },
      {
        path: "allowance-information",
        component: () => import("../components/Allowance.vue"),
      },
      {
        path: "local-assets-and-liabilities",
        component: () => import("../components/LocalAssets.vue"),
      },
      {
        path: "foreign-assets-and-liabilities",
        component: () => import("../components/ForeignAssets.vue"),
      },
      {
        path: "income-and-expenses",
        component: () => import("../components/Income.vue"),
      },
      {
        path: "change-password",
        component: () => import("../components/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/deposit",
    name: "Deposit",
    component: Deposit,
  },
  {
    path: "/trade",
    name: "Trade",
    component: Trade,
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: Withdraw,
    children: [
      {
        path: "bank-account",
        component: () => import("../components/Bank.vue"),
      },
      {
        path: "withdrawal",
        component: () => import("../components/Withdrawal.vue"),
        props: (route) => ({ query: route.query.id }),
      },
    ],
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/capitec",
    name: "Capitec",
    component: Capitec,
    children: [
      {
        path: "transaction-history",
        component: () => import("../components/TransactionHistory.vue"),
      },
    ],
  },
  {
    path: "/ait-applications",
    name: "AITApplications",
    component: AITApplications,
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: Referrals,
  },
  {
    path: "/trade-continuation",
    name: "TradeContinuation",
    component: TradeContinuation,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/sign-in",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  //routes: routes,
});

router.beforeEach((to, from, next) => {
  // redirect to sign in page if not signed in and trying to access a restricted page
  const publicPages = [
    "/",
    "/sign-in",
    "/forgot-password",
    "/forgot-password/reset",
  ];
  const authRequired = !publicPages.includes(to.path);
  var loggedIn = false;
  var token = JSON.parse(localStorage.getItem("token"));

  if (token != null) {
    var decode_refresh = jwt_decode(token.refresh);
    if (Date.now() < decode_refresh.exp * 1000) {
      loggedIn = true;
    }
  }

  if (authRequired && !loggedIn) {
    localStorage.removeItem("clientUrl");
    localStorage.removeItem("token");
    localStorage.removeItem("fullUserName");
    localStorage.removeItem("shortUserName");
    localStorage.removeItem("clientStatus");
    localStorage.removeItem("serviceLevel");
    localStorage.removeItem("tradingStatus");
    localStorage.removeItem("creditChoice");
    localStorage.removeItem("referralActive");
    return next("/sign-in");
  }

  if (!authRequired && loggedIn) {
    return next("/home");
  }

  next();
});

//export { routes };

export default router;
